import { MODIFY_USER_PHONE } from '~/redux/actions/types'

export const setUserPhone = payload => ({
  type: MODIFY_USER_PHONE,
  payload
})

export const modifyUserPhone = phone => async (dispatch, getState) => {
  await dispatch(setUserPhone(phone))

  try {
    const { user } = getState()
    fetch('/api/customer', {
      method: 'POST',
      body: JSON.stringify({
        customerId: user.data.id,
        phone: phone
      })
    }).catch(err => {
      throw new Error(err)
    })
  } catch (error) {
    dispatch(setUserPhone(null))
    throw error
  }
}
