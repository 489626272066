import { useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '~/redux/actions/auth'

export function useAuth() {
  const dispatch = useDispatch()
  const status = useSelector(state => state.auth.status)
  const token = useSelector(state => state.auth.token)
  const isLoggedIn = !!token

  const getToken = useMemo(
    () => bindActionCreators(actions.getToken, dispatch),
    [dispatch]
  )

  const login = useMemo(
    () => bindActionCreators(actions.login, dispatch),
    [dispatch]
  )

  const logout = useMemo(
    () => bindActionCreators(actions.logout, dispatch),
    [dispatch]
  )

  const recover = useMemo(
    () => bindActionCreators(actions.recover, dispatch),
    [dispatch]
  )

  const register = useMemo(
    () => bindActionCreators(actions.register, dispatch),
    [dispatch]
  )

  const resetPassword = useMemo(
    () => bindActionCreators(actions.resetPassword, dispatch),
    [dispatch]
  )

  return {
    isLoggedIn,
    status,
    token,
    getToken,
    login,
    logout,
    recover,
    register,
    resetPassword
  }
}
