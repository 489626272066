import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { gladlyInit } from './gladly.js'

const Chat = () => {
  const router = useRouter()

  useEffect(() => {
    gladlyInit()
  }, [])

  // Trigger Gladly update on URL changes
  useEffect(() => {
    const handleRouteChange = () => {
      window.Gladly.navigate()
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  return null
}

export default Chat
