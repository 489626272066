import {
  CREATE_USER_ADDRESS_REQUEST,
  CREATE_USER_ADDRESS_SUCCESS,
  CREATE_USER_ADDRESS_ERROR
} from '~/redux/actions/types'
import { getKlaviyoProfileSuccess } from '~/redux/actions/user/klaviyo'

export const createUserAddressRequest = () => ({
  type: CREATE_USER_ADDRESS_REQUEST
})

export const createUserAddressSuccess = payload => ({
  type: CREATE_USER_ADDRESS_SUCCESS,
  payload
})

export const createUserAddressError = error => ({
  type: CREATE_USER_ADDRESS_ERROR,
  payload: error,
  error: true
})

export const createUserAddress =
  (
    address,
    isDefaultAddress = false,
    whatsAppNumber,
    prefersWhatsApp,
    locale
  ) =>
  async (dispatch, getState, { api }) => {
    dispatch(createUserAddressRequest())
    const { auth, user } = getState()

    try {
      let klaviyoResponse
      if (user?.data?.klaviyoProfile?.id) {
        klaviyoResponse = await fetch(
          `/api/klaviyo/profile?id=${user.data.klaviyoProfile.id}`,
          {
            method: 'PATCH',
            body: JSON.stringify({
              email: user.data.email,
              first_name: user.data.firstName,
              last_name: user.data.lastName,
              whatsAppNumber,
              prefersWhatsApp,
              locale
            })
          }
        )
      } else {
        klaviyoResponse = await fetch(`/api/klaviyo/profile`, {
          method: 'POST',
          body: JSON.stringify({
            email: user.data.email,
            first_name: user.data.firstName,
            last_name: user.data.lastName,
            whatsAppNumber,
            prefersWhatsApp,
            locale
          })
        })
      }
      const klaviyoResponseJSON = await klaviyoResponse.json()
      const response = await api.createUserAddress(
        auth.token,
        address,
        isDefaultAddress
      )
      dispatch(createUserAddressSuccess(response))
      dispatch(getKlaviyoProfileSuccess(klaviyoResponseJSON.payload))
    } catch (error) {
      dispatch(createUserAddressError(error))
      throw error
    }
  }
