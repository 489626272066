import dynamic from 'next/dynamic'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

import MobileFooter from '~/components/Global/Footer/Mobile'
import SearchDrawer from '~/components/Global/Drawer/Search'
import LoginModal from '~/components/Account/Login/Modal'
import TermsModal from '~/components/Account/Terms/Modal'
import { useOverlay, useUser } from '~/hooks/actions'
import { useSelector } from 'react-redux'
import AccountVerificationAlert from '~/components/Account/Verification/Alert'
import AuctionWonOverlay from '~/components/Global/AuctionWonOverlay'
import DisabledBidAlert from '~/components/Common/DisabledBidAlert'
import NotificationsToaster from '~/components/Global/Notifications'
import cx from 'classnames'
import config from '~/../site-config.js'
import NewsletterModal from '~/components/Newsletter/Modal'
import useBastaWebSocket from '~/hooks/basta/useBastaWebsocket'

const DynamicBanner = dynamic(() => import('~/components/Global/Banner'))
const DynamicHeader = dynamic(() => import('~/components/Global/Header'))
const DynamicFooter = dynamic(() => import('~/components/Global/Footer'))
const DynamicNavDrawer = dynamic(() => import('~/components/Global/Drawer/Nav'))

const Layout = ({ children }) => {
  const { banner, nav } = config.header
  const [mobileMenu, setMobileMenu] = useState(false)
  const [searchDrawer, setSearchDrawer] = useState(false)
  const {
    contentOverlay,
    toggleContentOverlay,
    alertOverlay,
    toggleAlertOverlay
  } = useOverlay()
  const salesWon = useSelector(state => state.basta.salesWon)
  const router = useRouter()
  const { user } = useUser()
  useBastaWebSocket()

  // Close all drawers on page change
  useEffect(() => {
    mobileMenu && setMobileMenu(false) // Mobile Nav
    searchDrawer && setSearchDrawer(false) // Search Bar
    alertOverlay && toggleAlertOverlay() // Content Overlay
    if (router.asPath !== '/') {
      contentOverlay && toggleContentOverlay() // Content Overlay
    }
  }, [router.asPath])

  return (
    <>
      {/* Ticker Banner */}
      <DynamicBanner
        banner={banner}
        mobileMenu={mobileMenu}
        setMobileMenu={setMobileMenu}
        locale={router.locale}
      />
      {/* Header */}
      <DynamicHeader nav={nav} />
      {/* Overlay Toggle */}
      {/*<ContentOverlaySwitch />*/}
      {/* Body */}
      <main
        id="MainContent"
        role="main"
        className={cx({ overlayActive: contentOverlay })}>
        {children}
      </main>
      {/* Footer */}
      <DynamicFooter />
      {/* Mobile - Sticky Footer */}
      <MobileFooter toggleSearchDrawer={() => setSearchDrawer(!searchDrawer)} />
      {/* Mobile - Nav Drawer */}
      <DynamicNavDrawer mobileMenu={mobileMenu} />
      {/* Mobile - Search Drawer */}
      <SearchDrawer
        status={searchDrawer}
        toggle={() => setSearchDrawer(!searchDrawer)}
      />
      {/*Login Modal - Overlay  */}
      <LoginModal />
      {/*Terms Agreement Modal - Overlay  */}
      <TermsModal />
      {/*Newsletter Sign up Modal - Overlay  */}
      <NewsletterModal />
      {/* Auction Won Overlay */}
      {salesWon?.length > 0 && <AuctionWonOverlay salesWon={salesWon} />}
      {/* Verification Alert - Overlay */}
      <AccountVerificationAlert />
      {/* Disabled Bid Alert */}
      <DisabledBidAlert />
      {/* Notification Toaster*/}
      {user ? <NotificationsToaster /> : null}
    </>
  )
}

export default Layout
