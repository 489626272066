import { useRouter } from 'next/router'
import ReactModal from 'react-modal'

import { useOverlay, useTranslate } from '~/hooks/actions'
import styles from './index.module.scss'

const AccountVerificationAlert = () => {
  const { alertOverlay, toggleAlertOverlay } = useOverlay()
  const router = useRouter()
  const translate = useTranslate()

  const handleClick = e => {
    e.preventDefault()
    toggleAlertOverlay()
    router.push('/account')
  }

  return (
    <ReactModal
      isOpen={alertOverlay}
      onRequestClose={toggleAlertOverlay}
      ariaHideApp={false}
      className={`ReactModal__Content ${styles.alertModal}`}
      contentLabel="Verification Alert Modal">
      <div className={styles.container}>
        <h1 className={styles.heading}>
          {translate('account.verification.success.header')}
        </h1>
        <div className={styles.children}>
          <p>
            <span>{translate('account.verification.success.message')}</span>
            <br />
            <br />
            <span>
              {translate('account.verification.success.status_message')}
            </span>
          </p>
          <button
            type="button"
            className="cta cta--purple"
            style={{ width: '100%' }}
            onClick={e => handleClick(e)}>
            {translate('account.verification.success.view_account')}
          </button>
        </div>
      </div>
    </ReactModal>
  )
}
export default AccountVerificationAlert
