import { useDispatch, useSelector } from 'react-redux'
import * as actions from '~/redux/actions/basta'
import { bindActionCreators } from 'redux'
import { useMemo } from 'react'
import { createClient } from 'graphql-ws'

export const useBasta = () => {
  const basta = useSelector(state => state.basta)
  const dispatch = useDispatch()

  const getWsClient = useMemo(
    () => userId => {
      if (typeof window === 'object') {
        return createClient({
          url: 'wss://client.api.basta.ai/graphql',
          connectionParams: async () => {
            if (userId) {
              const response = await fetch(`/api/basta/token`, {
                method: 'POST',
                body: JSON.stringify({ userId: btoa(userId) })
              })
              const json = await response.json()
              return {
                token: `${json.data.createBidderToken.token}`
              }
            } else {
              return ''
            }
          }
        })
      } else {
        return undefined
      }
    },
    []
  )

  const closeSale = useMemo(
    () => bindActionCreators(actions.closeSale, dispatch),
    [dispatch]
  )
  const getAuction = useMemo(
    () => bindActionCreators(actions.getAuction, dispatch),
    [dispatch]
  )
  const getAuctions = useMemo(
    () => bindActionCreators(actions.getAuctions, dispatch),
    [dispatch]
  )
  const getClientAuction = useMemo(
    () => bindActionCreators(actions.getClientSale, dispatch),
    [dispatch]
  )
  const getActiveClientAuctions = useMemo(
    () => bindActionCreators(actions.getActiveClientSales, dispatch),
    [dispatch]
  )
  const getClientBids = useMemo(
    () => bindActionCreators(actions.getClientBids, dispatch),
    [dispatch]
  )
  const bid = useMemo(
    () => bindActionCreators(actions.bid, dispatch),
    [dispatch]
  )
  const setBiddingStep = useMemo(
    () => bindActionCreators(actions.setBiddingStep, dispatch),
    [dispatch]
  )
  const setMaxBid = useMemo(
    () => bindActionCreators(actions.setMaxBid, dispatch),
    [dispatch]
  )
  const addActivity = useMemo(
    () => bindActionCreators(actions.addActivity, dispatch),
    [dispatch]
  )
  const removeActivity = useMemo(
    () => bindActionCreators(actions.removeActivity, dispatch),
    [dispatch]
  )

  const updateItem = useMemo(
    () => bindActionCreators(actions.updateItemSuccess, dispatch),
    [dispatch]
  )

  return {
    basta,
    closeSale,
    getAuction,
    getAuctions,
    getClientAuction,
    getActiveClientAuctions,
    getClientBids,
    getWsClient,
    addActivity,
    removeActivity,
    bid,
    setBiddingStep,
    setMaxBid,
    updateItem
  }
}
