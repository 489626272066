import { sleep } from '~/utils'

export const fetchGraphQL = async query => {
  const space = process.env.CONTENTFUL_SPACE_ID
  const environment = process.env.CONTENTFUL_ENVIRONMENT
  const token = process.env.CONTENTFUL_ACCESS_TOKEN

  const fetchUrl = `https://graphql.contentful.com/content/v1/spaces/${space}/environments/${environment}`

  const fetchOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({ query })
  }

  const maxRetries = 10
  let retries = 0

  while (retries < maxRetries) {
    try {
      const response = await fetch(fetchUrl, fetchOptions)
      const data = await response.json()

      if (
        data.errors &&
        data.errors.some(
          error => error?.extensions?.contentful?.code === 'RATE_LIMIT_EXCEEDED'
        )
      ) {
        // Handle rate limit error
        await sleep(10000) // Sleep for 1 minute (or adapt as needed)
        retries++
        continue
      }

      if (data.errors && data.errors.length > 0) {
        data.errors.map(error => {
          console.log(error)
          console.log(fetchOptions.body)
          if (error?.extensions?.contentful.code === 'UNRESOLVABLE_LINK') {
            error.path.reduce(
              (currentLocation, nextLocation, currentIndex, path) => {
                if (path && currentIndex === path.length - 1) {
                  if (currentLocation.isArray) {
                    currentLocation.splice(nextLocation, 1)
                    return
                  } else if (typeof currentLocation === 'object') {
                    delete currentLocation[nextLocation]
                    return
                  }
                }
                return currentLocation[nextLocation]
              },
              data.data
            )
          }
        })
      } else {
        return data
      }
    } catch (error) {
      throw new Error(`Could not fetch data from Contentful: ${error.message}`)
    }
  }
  throw new Error(
    "Max retries reached. The API might be down or there's a persistent rate limit issue."
  )
}
