import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import * as actions from '../../redux/actions/cart'
import { useMemo } from 'react'
import { bindActionCreators } from 'redux'

export function useCart() {
  const cart = useSelector(state => state.cart, shallowEqual)
  const dispatch = useDispatch()

  const addPromoCode = useMemo(
    () => bindActionCreators(actions.addPromoCode, dispatch),
    [dispatch]
  )

  const addToCart = useMemo(
    () => bindActionCreators(actions.addToCart, dispatch),
    [dispatch]
  )

  const applyGiftCard = useMemo(
    () => bindActionCreators(actions.applyGiftCard, dispatch),
    [dispatch]
  )

  const checkout = useMemo(
    () => bindActionCreators(actions.checkout, dispatch),
    [dispatch]
  )

  const loadCart = useMemo(
    () => bindActionCreators(actions.loadCart, dispatch),
    [dispatch]
  )

  const modifyQuantity = useMemo(
    () => bindActionCreators(actions.modifyQuantity, dispatch),
    [dispatch]
  )

  const removeFromCart = useMemo(
    () => bindActionCreators(actions.removeFromCart, dispatch),
    [dispatch]
  )

  const removeGiftCard = useMemo(
    () => bindActionCreators(actions.removeGiftCard, dispatch),
    [dispatch]
  )

  const removePromoCode = useMemo(
    () => bindActionCreators(actions.removePromoCode, dispatch),
    [dispatch]
  )

  return {
    cart,
    addPromoCode,
    addToCart,
    applyGiftCard,
    checkout,
    loadCart,
    modifyQuantity,
    removeFromCart,
    removeGiftCard,
    removePromoCode
  }
}
