import { SET_BIDDING_STEP } from '~/redux/actions/types'

export const setBiddingStep = ({ biddingStep, itemId }) => {
  return async dispatch => {
    dispatch(setBiddingStepAction({ biddingStep, itemId }))
  }
}

export const setBiddingStepAction = ({ biddingStep, itemId }) => {
  return {
    type: SET_BIDDING_STEP,
    data: { biddingStep, itemId }
  }
}
