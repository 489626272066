import React, { useEffect, useState } from 'react'
import storage from '~/services/storage'

const ExchangeContext = React.createContext({
  setSelectedCurrency: () => {},
  selectedCurrency: null,
  exchangeData: {}
})

export const ExchangeContextProvider = props => {
  const fetcher = (...args) => fetch(...args).then(res => res.json())
  const [selectedCurrency, setSelectedCurrency] = useState('USD')
  const [exchangeData, setExchangeData] = useState({
    data: {},
    error: null,
    isLoading: true
  })
  useEffect(() => {
    fetch(`/api/exchange?base=USD`, fetcher)
      .then(res => res.json())
      .then(json =>
        setExchangeData({ data: json, error: null, isLoading: false })
      )
      .catch(err =>
        setExchangeData({ data: null, error: { err }, isLoading: false })
      )
    const storedCurrency = storage.getItem('selectedCurrency')
    setSelectedCurrency(storedCurrency || 'USD')
  }, [])
  useEffect(() => {
    storage.setItem('selectedCurrency', selectedCurrency)
  }, [selectedCurrency])
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: selectedCurrency,
    minimumFractionDigits: 2
  })

  const fraction = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: selectedCurrency,
    minimumFractionDigits: 0
  })
  const convertToCurrency = (number, type = 'integer') => {
    if (!isNaN(parseInt(number))) {
      number *=
        (exchangeData?.data?.rates &&
          exchangeData?.data?.rates[selectedCurrency]) ||
        1
      if (type === 'integer') {
        const currencyNum = number / 100
        if (currencyNum % 1 === 0) return fraction.format(currencyNum)
        else return formatter.format(currencyNum)
      } else if (type === 'float') {
        if (number % 1 === 0) return fraction.format(number)
        else return formatter.format(number)
      } else {
        const currencyNum = number / 100
        if (currencyNum % 1 === 0) return fraction.format(currencyNum)
        else return formatter.format(currencyNum)
      }
    } else {
      return null
    }
  }
  const value = React.useMemo(
    () => ({
      convertToCurrency,
      selectedCurrency,
      exchangeData,
      setSelectedCurrency
    }),
    [convertToCurrency, selectedCurrency, exchangeData, setSelectedCurrency]
  )
  return <ExchangeContext.Provider value={value} {...props} />
}

export const useExchange = () => {
  const context = React.useContext(ExchangeContext)
  if (!context) {
    throw new Error('You need to wrap this with ExchangeContextProvider')
  }
  return context
}
