import { createSelector } from 'reselect'

const getItems = state => state.notifications || {}

const getNotificationsCount = createSelector(
  getItems,
  items => Object.keys(items).length
)

export default getNotificationsCount
