import {
  ADD_PROMO_CODE_ERROR,
  ADD_PROMO_CODE_REQUEST,
  ADD_PROMO_CODE_SUCCESS
} from '~/redux/actions/types'

import { getOrLoadCart } from './get-or-load-cart'

export const addPromoCodeRequest = promoCode => {
  return {
    type: ADD_PROMO_CODE_REQUEST,
    promoCode
  }
}

export const addPromoCodeSuccess = (cart, promoCode) => {
  return {
    type: ADD_PROMO_CODE_SUCCESS,
    data: cart,
    promoCode
  }
}

export const addPromoCodeError = error => {
  return {
    type: ADD_PROMO_CODE_ERROR,
    error: true,
    payload: error
  }
}

export const addPromoCode =
  promoCode =>
  async (dispatch, getState, { api }) => {
    try {
      dispatch(addPromoCodeRequest(promoCode))

      const { data: cart } = await getOrLoadCart(dispatch, getState)

      const updatedCart = await api.addPromoCode(cart.id, promoCode)

      dispatch(addPromoCodeSuccess(updatedCart, promoCode))
    } catch (error) {
      dispatch(addPromoCodeError(error))
      throw error
    }
  }
