import {
  SET_BOOKMARK,
  LOAD_BOOKMARKS,
  INITIALIZE_BOOKMARKS_FROM_STORAGE
} from '~/redux/actions/types'
import initialState from '~/redux/store/initial-state'

const bookmarks = (state = initialState.bookmarks, action) => {
  switch (action.type) {
    case SET_BOOKMARK: {
      const { productId, value } = action.payload
      if (value) {
        return {
          ...state,
          [productId]: true
        }
      } else {
        const copy = { ...state }
        delete copy[productId]
        return copy
      }
    }

    case LOAD_BOOKMARKS:
    case INITIALIZE_BOOKMARKS_FROM_STORAGE:
      return action.payload.bookmarks

    default:
      return state
  }
}

export default bookmarks
