import { createSelector } from 'reselect'

const getCartTotal = state => state.cart.data && state.cart.data.totalPrice

const freeShippingThreshold = process.env.FREE_SHIPPING_THRESHOLD || 0

const getAmountNeededForFreeShipping = createSelector([getCartTotal], total => {
  const difference = freeShippingThreshold - total

  // round a negative difference (which indicates the cart total is larger than the threshold) to zero
  return Math.max(0, difference)
})

export default getAmountNeededForFreeShipping
