export const identify =
  ({ traits } = {}) =>
  async (_dispatch, getState, { api }) => {
    const { user } = getState()
    api.analytics.identify({ traits, user: user.data })
  }

export const trackCartViewed =
  () =>
  (_dispatch, getState, { api }) => {
    const { cart, user } = getState()
    api.analytics.trackCartViewed({ order: cart.data, user: user.data })
  }

export const trackCollectionClicked =
  ({ id, description, imageurl, slug, title } = {}) =>
  (_dispatch, _getState, { api }) => {
    api.analytics.trackCollectionClicked({
      id,
      description,
      imageUrl: imageurl,
      slug,
      title
    })
  }

export const trackEmailCaptured =
  ({ email, placement } = {}) =>
  (_dispatch, _getState, { api }) => {
    api.analytics.trackEmailCaptured({
      email,
      placement
    })
  }

export const trackProductClicked =
  ({ productHandle } = {}) =>
  async (_dispatch, getState, { api }) => {
    const { cart, user } = getState()
    api.analytics.trackProductClicked({
      productHandle,
      order: cart.data,
      user: user.data
    })
  }

export const trackProductListFiltered =
  ({ filter, list, sort } = {}) =>
  async (_dispatch, _getState, { api }) => {
    api.analytics.trackProductListFiltered({ filter, list, sort })
  }

export const trackProductListViewed =
  ({ list } = {}) =>
  async (_dispatch, _getState, { api }) => {
    api.analytics.trackProductListViewed({ list })
  }

export const trackProductViewed =
  ({ productHandle } = {}) =>
  async (_dispatch, getState, { api }) => {
    const { cart, user } = getState()
    api.analytics.trackProductViewed({
      productHandle,
      order: cart.data,
      user: user.data
    })
  }

export const trackPromotionClicked =
  ({ creative, id, name, position } = {}) =>
  (_dispatch, getState, { api }) => {
    const { user } = getState()
    api.analytics.trackPromotionClicked({
      promotion: { creative, id, name, position },
      user: user.data
    })
  }

export const trackPromotionViewed =
  ({ creative, id, name, position } = {}) =>
  (_dispatch, getState, { api }) => {
    const { user } = getState()
    api.analytics.trackPromotionViewed({
      promotion: { creative, id, name, position },
      user: user.data
    })
  }

export const trackStockRequestCreated =
  ({ email, productHandle } = {}) =>
  (_dispatch, _getState, { api }) => {
    api.analytics.trackStockRequestCreated({ email, productHandle })
  }

export const trackUserPasswordResetCompleted =
  () =>
  async (_dispatch, getState, { api }) => {
    const { user } = getState()
    api.analytics.trackUserPasswordResetCompleted({ user: user.data })
  }

export const trackUserPasswordResetStarted =
  ({ email } = {}) =>
  async (_dispatch, _getState, { api }) => {
    api.analytics.trackUserPasswordResetStarted({ email })
  }

export const trackUserSignedIn =
  () =>
  async (_dispatch, getState, { api }) => {
    const { user } = getState()
    api.analytics.trackUserSignedIn({ user: user.data })
  }

export const trackUserSignedOut =
  () =>
  async (_dispatch, getState, { api }) => {
    const { user } = getState()
    api.analytics.trackUserSignedOut({ user: user.data })
  }

export const trackUserSignedUp =
  () =>
  async (_dispatch, getState, { api }) => {
    const { user } = getState()
    api.analytics.trackUserSignedUp({
      signupType: 'organic',
      user: user.data
    })
  }

// 1R added events
const analytics = () => {
  return typeof window !== 'undefined' && window.analytics
}

const segmentTrackingMeta = {
  i18n: {
    currency: 'USD',
    locale: 'en'
  },
  ownership: {
    store_id: process.env.CHORD_STORE_ID,
    tenant_id: process.env.CHORD_TENANT_ID
  },
  platform: {
    name: 'Shopify',
    type: 'web'
  },
  store: {
    domain: process.env.SHOPIFY_SUBDOMAIN
  },
  version: {
    major: 2,
    minor: 1,
    patch: 1
  }
}

const bidTracking = async (action, bidItem, state) => {
  console.log(action)
  // console.log(state)
  // console.log(bidItem)

  const payload = {
    amount: 0,
    currency: segmentTrackingMeta.i18n.currency,
    meta: segmentTrackingMeta,
    product_name: bidItem ? bidItem.node.title : '',
    product_id: bidItem.productSlug ? bidItem.productSlug : '',
    product_slug: bidItem.productSlug ? bidItem.productSlug : '',
    user_email: state.user ? state.user.data.email : ''
  }

  switch (action) {
    case 'Bid Clicked':
      break

    case 'Bid Confirmed':
      payload.amount = bidItem.maxBid ? bidItem.maxBid : 0
      break

    case 'Bid Placed':
      payload.amount = bidItem.maxBid ? bidItem.maxBid : 0
      break

    default:
      break
  }

  const a = analytics()
  a.track(action, payload)
}

const pageTracking = async (action, page) => {
  console.log(action)
  // console.log(page);

  const payload = {
    meta: segmentTrackingMeta,
    title: page.title ? page.title : '',
    slug: page.slug ? page.slug : ''
  }

  switch (action) {
    case 'Article Viewed':
      break

    default:
      break
  }

  const a = analytics()
  a.track(action, payload)
}

const searchTracking = async (action, data) => {
  console.log(action)
  // console.log(data);

  const payload = {
    meta: segmentTrackingMeta,
    search_term: data.search ? data.search : ''
  }

  const a = analytics()
  a.track(action, payload)
}

const bookMarkTracking = async (action, productSlug, state) => {
  console.log(action)
  console.log(productSlug)
  console.log(state)

  const payload = {
    currency: segmentTrackingMeta.i18n.currency,
    meta: segmentTrackingMeta,
    // product_name: item ? item.node.title : '',
    product_id: productSlug,
    product_slug: productSlug,
    user_email: state.user ? state.user.data.email : ''
  }

  const a = analytics()
  a.track(action, payload)
}

export const trackBidClicked =
  ({ bidItem } = {}) =>
  async (_dispatch, getState) => {
    const state = getState()
    bidTracking('Bid Clicked', bidItem, state)
  }

export const trackBidConfirmed =
  ({ bidItem } = {}) =>
  async (_dispatch, getState) => {
    const state = getState()
    bidTracking('Bid Confirmed', bidItem, state)
  }

export const trackBidPlaced =
  ({ bidItem } = {}) =>
  async (_dispatch, getState) => {
    const state = getState()
    bidTracking('Bid Placed', bidItem, state)
  }

export const trackArticleViewed =
  ({ article } = {}) =>
  async () => {
    pageTracking('Article Viewed', article)
  }

export const trackSearchViewed =
  ({ data } = {}) =>
  async () => {
    searchTracking('Search Viewed', data)
  }

export const trackBookmarkAdded =
  ({ productSlug } = {}) =>
  async (_dispatch, getState) => {
    const state = getState()
    bookMarkTracking('Bookmark Added', productSlug, state)
  }
