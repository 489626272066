import { useTranslate, useModal } from '~/hooks/actions'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import Metadata from '~/components/Metadata'
import AccountWrapper from '~/components/Account/Wrapper'
import AuthLoginForm from '~/components/Account/Login/Form'
import Modal from 'react-modal'

import modalStyles from './Modal.module.scss'

Modal.defaultStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backdropFilter: 'blur(5px)',
    zIndex: 1001,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch'
  }
}

const LoginModal = () => {
  const translate = useTranslate()
  const router = useRouter()
  const { isOpen, message, closeModal, callback } = useModal()

  const handleSuccessfulLogin = () => {
    callback()
    closeModal()
  }

  const handleKeyPress = e => {
    if (e.key === 'Escape') {
      closeModal()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  useEffect(() => {
    closeModal()
  }, [router.route])

  return (
    <>
      <Modal
        isOpen={isOpen}
        shouldCloseOnEsc={false}
        className={modalStyles.modalContent}>
        <Metadata title={translate('account.login_modal.page_title')} />
        <AccountWrapper
          title={translate('account.login_modal.page_title')}
          style={{ background: 'rgba(1, 1, 1, 1)' }}
          close={() => closeModal()}>
          <AuthLoginForm callback={handleSuccessfulLogin} heading={message} />
        </AccountWrapper>
      </Modal>
    </>
  )
}

export default LoginModal
