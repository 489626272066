import { BID_ERROR, BID_REQUEST, BID_SUCCESS } from '~/redux/actions/types'

export const bidRequest = ({ saleId, itemId, amount, userId }) => {
  return {
    type: BID_REQUEST,
    saleId: saleId,
    itemId: itemId,
    amount: amount,
    userId: userId
  }
}

export const bidSuccess = bid => {
  return {
    type: BID_SUCCESS,
    data: bid
  }
}

export const bidError = (error, meta = {}) => {
  return {
    type: BID_ERROR,
    error: true,
    meta,
    data: error
  }
}

export const bid = ({ saleId, itemId, amount, userId, type }) => {
  const encodedUserId = btoa(userId)
  return async dispatch => {
    try {
      dispatch(bidRequest({ saleId, itemId, amount, encodedUserId }))
      const bid = await fetch(`/api/basta/bid`, {
        method: 'POST',
        body: JSON.stringify({
          type,
          saleId,
          itemId,
          amount,
          userId: encodedUserId
        })
      })
      const bidResult = await bid.json()
      dispatch(bidSuccess(bidResult?.data?.bidOnItem))
    } catch (e) {
      dispatch(bidError(e, { type, saleId, itemId, amount, encodedUserId }))
      throw e
    }
  }
}
