import { AmityStatus, AuthStatus, UserStatus } from '~/redux/actions/types'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  auth: {
    status: AuthStatus.Initial,
    token: null
  },
  cart: {
    data: {},
    isFetching: false,
    error: null,
    promoCode: {
      isFetching: false
    },
    giftCard: {
      isFetching: false
    }
  },
  user: {
    data: {},
    verified: false,
    idCheck: false,
    stripeCusId: null,
    stripeAccount: false,
    stripePayment: false,
    agreedToTerms: true,
    hasNewsletterSubscription: true,
    status: UserStatus.Initial
  },
  overlay: {
    content: false,
    verification: false,
    verificationStep: '',
    alert: false
  },
  basta: {
    auctions: {},
    biddingStep: {},
    maxBid: {},
    bidResult: {},
    clientAuctions: {},
    accountActivity: [],
    wsConnections: [],
    clientBids: [],
    activeClientAuctions: [],
    clientInstance: undefined,
    salesWon: []
  },
  bookmarks: {},
  modal: {
    isOpen: false,
    callback: () => console.log('Modal callback is unset!')
  },
  newsletter: {
    hideModal: true
  },
  session: {
    id: null
  },
  amity: {
    loadCommentsStatus: AmityStatus.Initial,
    addCommentStatus: AmityStatus.Initial,
    deleteCommentStatus: AmityStatus.Initial,
    checkPermissionsStatus: AmityStatus.Initial,
    canModerate: false,
    comments: []
  },
  notifications: [],
  filters: [false]
}
