import { useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '~/redux/actions/bookmarks'

export function useBookmarks() {
  const bookmarks = useSelector(state => state.bookmarks)
  const dispatch = useDispatch()

  const setBookmark = useMemo(
    () => bindActionCreators(actions.setBookmark, dispatch),
    [dispatch]
  )

  const loadBookmarks = useMemo(
    () => bindActionCreators(actions.loadBookmarks, dispatch),
    [dispatch]
  )

  return {
    bookmarks,
    setBookmark,
    loadBookmarks
  }
}
