export const PRODUCT_FRAGMENT = `
  name
  slug
  collectionName
  shortDescription
  longDescription
  bastaItemId
  bastaSaleId
  estimatedUsShipping
  estimatedInternationalShipping
  vendor
  productType {
    name
  }
  linkedFrom {
    collectionCollection {
      items {
        name
        auctionInquiriesTitle
        auctionInquiriesText {
          json
        }
      }
    }
  }
  richLongDescription{
    json
    links {
      assets {
        block {
          sys {
            id
          }
          title
          description
          url
          width
          height
        }
      }
    }
  }
  auctionNoticeTitle
  auctionNoticeText {
    json
    links {
      assets {
        block {
          sys {
            id
          }
          title
          description
          url
          width
          height
        }
      }
    }
  }
  mainImage {
    sys {
      id
    }
    title
    url
    width
    height
  }
  optionTypesCollection(limit: 3) {
    items {
      name
      slug
      presentation
    }
  }
  variantsCollection {
    items {
      sys {
        id
      }
    }
  }
  relatedContentType
  relatedContentCollection {
    items {
      __typename
      ... on Article {
        sys {
          id
        }
        title
        slug
        publishDate
        image {
          sys {
            id
          }
          title
          url
          width
          height
        }
        author {
          name
        }
      }
      ... on Product {
        sys {
          id
        }
        name
        slug
        isMerch
        mainImage {
          sys {
            id
          }
          title
          url
          width
          height
        }
        contentfulMetadata {
          tags {
            name
          }
        }
        variantsCollection(limit: 1) {
          items {
            sys {
              id
            }
            price
            estimatedMinimum
            estimatedMaximum
            reservePrice
            acceptingOffers
            pricingUponRequest
          }
        }
      }
    }
  }
  contentfulMetadata {
    tags {
      name
    }
  }
  communityFeedId
  amityPostId
`

export const VARIANT_FRAGMENT = `
  name
  sku
  price
  regularPrice
  estimatedMinimum
  estimatedMaximum
  reservePrice
              acceptingOffers
            pricingUponRequest
  weight
  shopifyId
  buyersFeePercentage
  conditionReport {
    json
    links {
      assets {
        block {
          sys {
            id
          }
          title
          description
          url
          width
          height
        }
      }
    }
  }
  appendix {
    json
    links {
      assets {
        block {
          sys {
            id
          }
          title
          description
          url
          width
          height
        }
      }
    }
  }
  optionValuesCollection(limit: 3)  {
    items {
      name
      slug
      presentation
      linkedFrom {
        optionTypeCollection {
          items {
            slug
          }
        }
      }
    }
  }
  mainImage {
    sys {
      id
    }
    title
    url
    fileName
    width
    height
  }
  imagesCollection(limit: 20) {
    items {
      sys {
        id
      }
      title
      url
      fileName
      width
      height
    }
  }
  landscapeOnly
`

export const BOOKMARK_PRODUCT_FRAGMENT = `
  slug
  name
  bastaItemId
  bastaSaleId
  isMerch
  variantsCollection {
    items {
      price
      estimatedMinimum
      estimatedMaximum
      reservePrice
                  acceptingOffers
            pricingUponRequest
    }
  }
  mainImage {
    sys {
      id
    }
    title
    url
    width
    height
  }
`

export const PRODUCT_SUMMARY_FRAGMENT = `
  name
  slug
  collectionName
  shortDescription
  bastaItemId
  bastaSaleId
  mainImage {
    sys {
      id
    }
    title
    url
    width
    height
  }
  variantsCollection {
    items {
      estimatedMinimum
      estimatedMaximum
      sku
      buyersFeePercentage
      reservePrice
      acceptingOffers
      pricingUponRequest
    }
  }
  contentfulMetadata {
    tags {
      name
    }
  }
`

export const PRODUCT_LOT_FRAGMENT = `
  slug
  name
  isMerch
  contentfulMetadata {
    tags {
      name
    }
  }
  linkedFrom {
    collectionCollection {
      items {
        name
      }
    }
  }
`

export const PRODUCT_ALP_FRAGMENT = `
name
slug
bastaItemId
bastaSaleId
productType {
  name
}
mainImage {
  sys {
    id
  }
  title
  url
  width
  height
}
variantsCollection {
  items {
    sys {
      id
    }
  }
}
contentfulMetadata {
  tags {
    name
  }
}
`
