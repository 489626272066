import {
  AUTH_RECOVER_ERROR,
  AUTH_RECOVER_REQUEST,
  AUTH_RECOVER_SUCCESS
} from '~/redux/actions/types'
import auth from '~/services/auth'
import { trackUserPasswordResetStarted } from '~/redux/actions/analytics'

export const recoverRequest = email => {
  return {
    type: AUTH_RECOVER_REQUEST,
    payload: {
      email
    }
  }
}

export const recoverSuccess = payload => {
  return {
    type: AUTH_RECOVER_SUCCESS,
    payload
  }
}

export const recoverError = (error, meta = {}) => {
  return {
    type: AUTH_RECOVER_ERROR,
    error: true,
    meta,
    payload: {
      error
    }
  }
}

export const recover = email => async dispatch => {
  try {
    dispatch(recoverRequest(email))
    await auth.recover(email)
    dispatch(recoverSuccess({ email }))
    dispatch(trackUserPasswordResetStarted({ email }))
  } catch (error) {
    dispatch(recoverError(error, { email }))
    throw error
  }
}
