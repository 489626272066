import initialState from '~/redux/store/initial-state'
import {
  ADD_ACTIVITY_MESSAGE,
  BID_ERROR,
  BID_REQUEST,
  BID_SUCCESS,
  CLIENT_SALE_ERROR,
  CLIENT_SALE_REQUEST,
  CLIENT_SALE_SUCCESS,
  GET_AUCTION_ERROR,
  GET_AUCTION_REQUEST,
  GET_AUCTION_SUCCESS,
  GET_AUCTIONS_ERROR,
  GET_AUCTIONS_REQUEST,
  GET_AUCTIONS_SUCCESS,
  SET_BIDDING_STEP,
  SET_MAX_BID,
  REMOVE_ACTIVITY_MESSAGE,
  BASTA_CLIENT_BIDS_ERROR,
  BASTA_CLIENT_BIDS_REQUEST,
  BASTA_CLIENT_BIDS_SUCCESS,
  BASTA_CLIENT_ACTIVE_AUCTIONS_ERROR,
  BASTA_CLIENT_ACTIVE_AUCTIONS_REQUEST,
  BASTA_CLIENT_ACTIVE_AUCTIONS_SUCCESS,
  ITEM_UPDATE_SUCCESS,
  ITEM_UPDATE_REQUEST,
  ITEM_UPDATE_ERROR,
  SET_CLIENT_INSTANCE,
  SALE_CLOSED
} from '~/redux/actions/types'

const basta = (state = initialState.basta, action) => {
  let accountActivity = state.accountActivity
  switch (action.type) {
    case BID_REQUEST:
    case BID_ERROR:
    case BID_SUCCESS:
      return {
        ...state,
        bidResult: action.data
      }
    case GET_AUCTION_REQUEST:
    case GET_AUCTION_SUCCESS:
      return {
        ...state,
        auctions: { ...state.auctions, [action.data?.id]: action.data }
      }
    case GET_AUCTION_ERROR:
      return {
        ...state
      }
    case GET_AUCTIONS_REQUEST:
    case GET_AUCTIONS_SUCCESS:
      return {
        ...state,
        auctions: { ...state.auctions, ...action.data }
      }
    case GET_AUCTIONS_ERROR:
    case CLIENT_SALE_ERROR:
    case CLIENT_SALE_REQUEST:
    case CLIENT_SALE_SUCCESS:
      return {
        ...state,
        clientAuctions: {
          ...state.clientAuctions,
          [action.data?.id]: action.data
        }
      }
    case SET_MAX_BID:
      return {
        ...state,
        maxBid: {
          ...state.maxBid,
          [action.data.itemId]: action.data.maxBid
        }
      }
    case SET_BIDDING_STEP:
      return {
        ...state,
        biddingStep: {
          ...state.biddingStep,
          [action.data.itemId]: action.data.biddingStep
        }
      }
    case ADD_ACTIVITY_MESSAGE:
      accountActivity.unshift(action.payload)
      return {
        ...state,
        accountActivity: accountActivity
      }
    case REMOVE_ACTIVITY_MESSAGE:
      state.accountActivity.splice(action.payload, 1)
      return {
        ...state,
        accountActivity: state.accountActivity
      }
    case BASTA_CLIENT_BIDS_REQUEST:
    case BASTA_CLIENT_BIDS_ERROR:
    case BASTA_CLIENT_BIDS_SUCCESS:
      return {
        ...state,
        clientBids: action.data
      }
    case BASTA_CLIENT_ACTIVE_AUCTIONS_REQUEST:
    case BASTA_CLIENT_ACTIVE_AUCTIONS_ERROR:
    case BASTA_CLIENT_ACTIVE_AUCTIONS_SUCCESS:
      return {
        ...state,
        activeClientAuctions: action.data ? [...action.data] : []
      }
    case SET_CLIENT_INSTANCE:
      return {
        ...state,
        clientInstance: action.data
      }
    case SALE_CLOSED:
      return {
        ...state,
        salesWon: [...state.salesWon, action.data]
      }
    case ITEM_UPDATE_REQUEST:
    case ITEM_UPDATE_ERROR:
    case ITEM_UPDATE_SUCCESS:
      return {
        ...state,
        clientAuctions: {
          ...state.clientAuctions,
          [action.data.saleId]: {
            ...state.clientAuctions[action.data.saleId],
            items: {
              edges: [
                {
                  node: {
                    ...action.data
                  }
                }
              ]
            }
          }
        }
      }
    default:
      return state
  }
}

export default basta
