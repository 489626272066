import cx from 'classnames'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'

import searchStyles from './Search.module.scss'

const SearchDrawer = ({ status, toggle }) => {
  const router = useRouter()
  const { handleSubmit, register, reset, setFocus } = useForm()

  useEffect(() => {
    status && setFocus('search')
  }, [status])

  // Handle Submit
  const onSearch = async (data, e) => {
    e.preventDefault()
    router.push(`/search?q=${data.search}`)
    toggle()
    reset()
  }

  return (
    <div
      className={cx('drawer', 'drawer--top', searchStyles.drawer, {
        'drawer--open': status
      })}
      aria-label="Search Drawer"
      aria-hidden={status ? 'false' : 'true'}>
      <div className={cx('drawer__content', searchStyles.content)}>
        <form className={searchStyles.form} onSubmit={handleSubmit(onSearch)}>
          <input
            type="text"
            className={cx('default', searchStyles.input)}
            placeholder="Search something..."
            {...register('search')}
          />

          <button type="submit" className={searchStyles.submit}>
            <span className="icon icon--search"></span>
          </button>
        </form>
      </div>
    </div>
  )
}
export default SearchDrawer
