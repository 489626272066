import {
  ASSOCIATE_CART_WITH_USER_ERROR,
  ASSOCIATE_CART_WITH_USER_REQUEST,
  ASSOCIATE_CART_WITH_USER_SUCCESS
} from '~/redux/actions/types'

export const associateCartRequest = () => {
  return {
    type: ASSOCIATE_CART_WITH_USER_REQUEST
  }
}

export const associateCartSuccess = cart => {
  return {
    type: ASSOCIATE_CART_WITH_USER_SUCCESS,
    data: cart
  }
}

export const associateCartError = error => {
  return {
    type: ASSOCIATE_CART_WITH_USER_ERROR,
    error: true,
    payload: error
  }
}

export const associateCartWithUser =
  () =>
  async (dispatch, getState, { api }) => {
    try {
      dispatch(associateCartRequest())
      const { auth, cart } = getState()
      const newCart = await api.associateOrderWithUser(cart.data.id, auth.token)
      dispatch(associateCartSuccess(newCart))
    } catch (error) {
      dispatch(associateCartError(error))
      throw error
    }
  }
