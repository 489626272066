import {
  ADD_ACTIVITY_MESSAGE,
  REMOVE_ACTIVITY_MESSAGE,
  SALE_CLOSED,
  SET_CLIENT_INSTANCE
} from '~/redux/actions/types'
import { getProductByBastaItemId } from '../../../../graphql-cms/products'

import { shopifyClient } from '~/services/shopify'

export const receiveWsMessage = message => {
  return {
    type: ADD_ACTIVITY_MESSAGE,
    payload: message
  }
}

export const removeMessage = index => {
  return {
    type: REMOVE_ACTIVITY_MESSAGE,
    payload: index
  }
}

export const saleClosed = data => {
  return {
    type: SALE_CLOSED,
    data: data
  }
}

export const setClientInstance = data => {
  return {
    type: SET_CLIENT_INSTANCE,
    data: data
  }
}

export const addActivity = message => {
  return dispatch => {
    try {
      dispatch(receiveWsMessage(message))
    } catch (e) {
      console.error(e)
    }
  }
}

export const removeActivity = index => {
  return dispatch => {
    try {
      dispatch(removeMessage(index))
    } catch (e) {
      console.error(e)
    }
  }
}

export const closeSale = ({ saleClosedEvent, userId }) => {
  return async dispatch => {
    try {
      const productData = await getProductByBastaItemId(
        saleClosedEvent.items.edges[0].node.id
      )
      const shopifyProductData = await shopifyClient({
        query: `{
          products(first: 1, query: "title:'${productData[0].name}'") {
            edges {
              node {
                isExcludedFromCheckout: metafield(key: "exclude_from_checkout", namespace: "custom") {
                  value
                }
              }
            }
          }
        }`
      })
      const draftOrdersRequest = await fetch(
        `/api/customer/orders/${userId.split('/').reverse()[0]}`
      )
      const draftOrdersResponse = await draftOrdersRequest.json()
      const soldDraftOrder =
        draftOrdersResponse.draftOrders.data.draftOrders.nodes.find(
          draftOrder => {
            return (
              productData[0].variantsCollection.items[0].sku ===
              draftOrder.lineItems.nodes[0].sku
            )
          }
        )
      if (soldDraftOrder) {
        productData[0].invoiceUrl = soldDraftOrder.invoiceUrl
      }
      const product = productData[0]

      /* eslint-disable */
      product.isExcludedFromCheckout =
        shopifyProductData?.data?.products?.edges[0].node?.isExcludedFromCheckout?.value === 'true' ?? false
      /* eslint-enable */
      dispatch(saleClosed({ product: product, saleClosedEvent }))
    } catch (e) {
      console.error(e)
    }
  }
}
