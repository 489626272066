import Head from 'next/head'
import Script from 'next/script'
import { useMemo } from 'react'
import { Provider } from 'react-redux'
import { useRouter } from 'next/router'
import { IntlProvider } from 'react-intl'
import { flattenMessages } from '~/utils/locales'
import { wrapper } from '~/redux/store'

import Layout from '~/components/Global/Layout'
import PageTracker from '~/components/PageTracker'

import QueryParamProvider from '~/context/query-param-provider'
import useVisualViewport from '~/hooks/components/use-visual-viewport'

import '~/styles/main.scss'

import English from '../locales/en.json'
import Japanese from '../locales/ja.json'
import Chinese from '../locales/zh.json'
import Korean from '../locales/ko.json'
import Chat from '~/components/Global/Chat'
import { ExchangeContextProvider } from '~/context/exchange'

function MyApp({ Component, ...rest }) {
  const { locale } = useRouter()
  const [shortLocale] = locale ? locale.split('-') : ['en']
  const { store, props } = wrapper.useWrappedStore(rest)

  const messagesForLocale = useMemo(() => {
    switch (shortLocale) {
      case 'ja':
        return Japanese
      case 'en':
        return English
      case 'zh':
        return Chinese
      case 'ko':
        return Korean
      default:
        return English
    }
  }, [shortLocale])

  // React Intl v2 no longer supports nested messages objects... I've imported all the messages
  // from the Gatsby project, so I'm just flattening them here for the sake of simplicity for this
  // demo project
  const messages = flattenMessages(messagesForLocale)
  const defaultMessages = flattenMessages(English)
  const combinedMessages = { ...defaultMessages, ...messages }

  // Grab device viewport size
  useVisualViewport()
  return (
    <>
      <Head>
        {/* Adobe Font Import - linotype-sabon */}
        <link rel="stylesheet" href="https://use.typekit.net/bud0bvw.css" />
      </Head>
      <Provider store={store}>
        <ExchangeContextProvider>
          <Script
            id="show-banner"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};;analytics.SNIPPET_VERSION="4.13.2";
          analytics.load("${process.env.SEGMENT_WRITE_KEY}");
          analytics.page();
          }}();`
            }}
          />
          <QueryParamProvider>
            <IntlProvider
              locale={locale}
              defaultLocale="en"
              messages={combinedMessages}
              onError={error => {
                if (error.code === 'MISSING_TRANSLATION') {
                  console.warn('Missing translation for', locale, error.message)
                  return // Ignore the MISSING_TRANSLATION error or handle it as needed
                }
                // Handle other errors as needed
                console.error(error)
              }}>
              <PageTracker>
                <Layout>
                  <Component {...props.pageProps} />
                </Layout>
              </PageTracker>
            </IntlProvider>
          </QueryParamProvider>
        </ExchangeContextProvider>
      </Provider>

      {/* Gladly Chat component */}
      <Chat />
    </>
  )
}

export default MyApp
