import { createSelector } from 'reselect'

const getItems = state => state.bookmarks || {}

const getBookmarksCount = createSelector(
  getItems,
  items => Object.keys(items).length
)

export default getBookmarksCount
