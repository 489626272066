import {
  GET_AUCTION_ERROR,
  GET_AUCTION_REQUEST,
  GET_AUCTION_SUCCESS
} from '~/redux/actions/types'

export const getAuctionRequest = id => {
  return {
    type: GET_AUCTION_REQUEST,
    id: id
  }
}

export const getAuctionSuccess = auction => {
  return {
    type: GET_AUCTION_SUCCESS,
    data: auction
  }
}

export const getAuctionError = (error, meta = {}) => {
  return {
    type: GET_AUCTION_ERROR,
    error: true,
    meta,
    data: error
  }
}

export const getAuction = id => {
  return async dispatch => {
    try {
      dispatch(getAuctionRequest(id))
      const sale = await fetch(`/api/basta/auctions/${id}`)
      const saleResponse = await sale.json()
      dispatch(getAuctionSuccess(saleResponse.data.sale))
    } catch (e) {
      dispatch(getAuctionError(e, { id }))
      throw e
    }
  }
}
