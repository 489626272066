import {
  ADD_COMMENT_REQUEST,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_ERROR
} from '~/redux/actions/types'

export const addCommentRequest = () => ({
  type: ADD_COMMENT_REQUEST
})

export const addCommentSuccess = payload => ({
  type: ADD_COMMENT_SUCCESS,
  payload
})

export const addCommentError = error => ({
  type: ADD_COMMENT_ERROR,
  error: true,
  payload: {
    error
  }
})

export const addComment =
  ({ postId, userId, displayName, content, parentId }) =>
  async dispatch => {
    dispatch(addCommentRequest())
    try {
      const response = await fetch('/api/amity/comments', {
        method: 'POST',
        body: JSON.stringify({
          postId,
          userId,
          displayName,
          content,
          ...{ parentId }
        })
      })
      const { comment } = await response.json()
      dispatch(addCommentSuccess({ comment, parentId }))
    } catch (error) {
      dispatch(addCommentError(error))
      throw error
    }
  }
