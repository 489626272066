import { createChordClient } from '@chordcommerce/chord-js-performance'

const checkoutDomain = process.env.SHOPIFY_CHECKOUT_DOMAIN
const domain = `${process.env.SHOPIFY_SUBDOMAIN}.myshopify.com`
const storefrontAccessToken = process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN
const multipassUrl = process.env.SHOPIFY_MULTIPASS_URL
const tenant = process.env.CHORD_TENANT_ID
const storeID = process.env.CHORD_STORE_ID
const storeName = process.env.SHOPIFY_SUBDOMAIN

const client = createChordClient({
  checkoutDomain,
  domain,
  multipassUrl,
  storefrontAccessToken,
  tenant,
  storeID,
  storeName
})

export default client
