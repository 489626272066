import {
  AUTH_LOGOUT_SUCCESS,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  MODIFY_USER_PHONE,
  MODIFY_USER_DEFAULT_ADDRESS_SUCCESS,
  MODIFY_USER_ADDRESS_SUCCESS,
  CREATE_USER_ADDRESS_SUCCESS,
  REMOVE_USER_ADDRESS_SUCCESS,
  MODIFY_USER_NAME,
  CLEAR_SESSION,
  UserStatus,
  USER_VERIFIED,
  VERIFY_USER_SUCCESS,
  IDCHECK_USER,
  IDCHECK_USER_SUCCESS,
  USER_AGREED_TO_TERMS,
  USER_AGREE_TO_TERMS_SUCCESS,
  CHECK_USER_STRIPE_PAYMENT,
  CHECK_USER_STRIPE_ACCOUNT,
  CHECK_USER_STRIPE_CUSID,
  USER_HAS_NEWSLETTER_SUBSCRIPTION,
  GET_KLAVIYO_PROFILE_SUCCESS
} from '~/redux/actions/types'
import initialState from '~/redux/store/initial-state'

const user = (state = initialState.user, action) => {
  switch (action.type) {
    case AUTH_LOGOUT_SUCCESS:
    case CLEAR_SESSION:
      return {
        data: {},
        verified: false,
        idCheck: false,
        stripeCusId: null,
        stripeAccount: false,
        stripePayment: false,
        status: UserStatus.Idle
      }

    case LOAD_USER_REQUEST:
      return {
        ...state,
        status: UserStatus.Loading
      }

    case LOAD_USER_SUCCESS:
    case CREATE_USER_ADDRESS_SUCCESS:
    case MODIFY_USER_ADDRESS_SUCCESS:
    case REMOVE_USER_ADDRESS_SUCCESS:
    case MODIFY_USER_DEFAULT_ADDRESS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: UserStatus.Succeeded
      }

    case MODIFY_USER_PHONE:
      return {
        ...state,
        data: { ...state.data, phone: action.payload }
      }

    case MODIFY_USER_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName
        }
      }

    case CHECK_USER_STRIPE_CUSID:
      return {
        ...state,
        stripeCusId: action.payload
      }

    case CHECK_USER_STRIPE_ACCOUNT:
      return {
        ...state,
        stripeAccount: action.payload
      }

    case CHECK_USER_STRIPE_PAYMENT:
      return {
        ...state,
        stripePayment: action.payload
      }

    case IDCHECK_USER_SUCCESS:
      return {
        ...state,
        idCheck: action.payload
      }

    case IDCHECK_USER:
      return {
        ...state,
        idCheck: true
      }

    case USER_HAS_NEWSLETTER_SUBSCRIPTION:
      return {
        ...state,
        hasNewsletterSubscription: action.payload
      }

    case VERIFY_USER_SUCCESS:
      return {
        ...state,
        verified: action.payload
      }

    case USER_VERIFIED:
      return {
        ...state,
        verified: true
      }

    case USER_AGREE_TO_TERMS_SUCCESS:
      return {
        ...state,
        agreedToTerms: action.payload
      }

    case USER_AGREED_TO_TERMS:
      return {
        ...state,
        agreedToTerms: true
      }

    case GET_KLAVIYO_PROFILE_SUCCESS:
      return {
        ...state,
        data: { ...state.data, klaviyoProfile: action.payload }
      }

    default:
      return state
  }
}

export default user
