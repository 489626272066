import { useEffect, useState } from 'react'
import { useTranslate, useUser } from '~/hooks/actions'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import Modal from 'react-modal'
import Metadata from '~/components/Metadata'
import AccountWrapper from '~/components/Account/Wrapper'

import formStyles from '~/components/Account/Form.module.scss'
import modalStyles from './Modal.module.scss'

Modal.defaultStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backdropFilter: 'blur(5px)',
    zIndex: 1001,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch'
  }
}

const TermsModal = () => {
  const [error, setError] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [onWhitelistedPage, setOnWhitelistedPage] = useState(false)

  const { handleSubmit, register, watch } = useForm({
    defaultValues: {
      hasAgreed: false
    }
  })
  const router = useRouter()
  const translate = useTranslate()
  const { user, agreedToTermsStatus, userAgreeToTerms } = useUser()
  const hasAgreed = watch('AcceptTerms')

  const onSubmit = async () => {
    setSubmitting(true)
    setError(null)
    try {
      await userAgreeToTerms()
      setSubmitting(false)
    } catch (error) {
      setSubmitting(false)
      setError(error)
    }
  }

  useEffect(() => {
    if (router.pathname.includes('support')) {
      setOnWhitelistedPage(true)
    } else {
      setOnWhitelistedPage(false)
    }
  }, [router.pathname])

  if (!user.id) {
    return null
  }

  return (
    <>
      <Modal
        isOpen={agreedToTermsStatus === false && !onWhitelistedPage}
        shouldCloseOnEsc={false}
        className={modalStyles.modal}>
        <Metadata title={translate('account.terms_modal.page_title')} />
        <AccountWrapper title={translate('account.terms_modal.page_title')}>
          <p className={modalStyles.modal__info}>
            {translate('account.terms_modal.info')}
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="fieldset">
              {/* {submitting ? 'loading' : 'not loading'} */}
              {/* {JSON.stringify(hasAgreed)} */}
              <div className="field">
                <div className="input-wrapper">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="AcceptTerms"
                      {...register('AcceptTerms')}
                      className="checkbox__input"
                    />
                    <span className="checkbox__icon"></span>
                    <span className="checkbox__label">
                      {translate('account.register.terms_label')}
                      <span>
                        <Link
                          href={{
                            pathname: '/support',
                            query: { slug: 'privacy-policy' }
                          }}
                          replace>
                          <a className="font--underlined">
                            {translate('account.register.privacy_policy')}
                          </a>
                        </Link>
                      </span>
                      ,{' '}
                      <span>
                        <Link
                          href={{
                            pathname: '/support',
                            query: { slug: 'terms-and-conditions' }
                          }}
                          replace>
                          <a className="font--underlined">
                            {translate('account.register.terms_and_conditions')}
                          </a>
                        </Link>
                      </span>
                      , and{' '}
                      <span>
                        <Link
                          href={{
                            pathname: '/support',
                            query: { slug: 'terms-of-sale' }
                          }}
                          replace>
                          <a className="font--underlined">
                            {translate('account.register.terms_of_sale')}
                          </a>
                        </Link>
                      </span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className={formStyles.actions}>
              <button
                type="submit"
                className="cta cta--purple"
                disabled={submitting || error || !hasAgreed}
                aria-disabled={submitting || error || !hasAgreed}>
                {submitting
                  ? translate('general.submitting')
                  : translate('general.submit_button')}
              </button>
            </div>
          </form>
        </AccountWrapper>
      </Modal>
    </>
  )
}

export default TermsModal
