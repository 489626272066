import NewsletterForm from '../Form'
import { useTranslate } from '~/hooks/actions'

import styles from './Section.module.scss'

const NewsletterSection = ({ className = '', close, closeCallback }) => {
  const translate = useTranslate()

  return (
    <div className={`${styles.section} ${className}`}>
      {close && (
        <button className={styles.close} onClick={closeCallback} type="button">
          [x]
        </button>
      )}
      <h1 className={styles.heading}>{translate('newsletter.page_title')}</h1>
      <div className={styles.children}>
        <NewsletterForm />
      </div>
    </div>
  )
}
export default NewsletterSection
