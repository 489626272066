import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AnimatePresence, motion } from 'framer-motion'
import notificationStyles from './notification.module.scss'
import { removeNotification } from '~/redux/actions'
import Link from '~/components/Generic/Link'
import { useExchange } from '~/context/exchange'

const Notification = ({
  index,
  icon,
  msg1,
  product,
  msg2,
  linkHref,
  linkText,
  price,
  displayTime
}) => {
  const dispatch = useDispatch()
  const { convertToCurrency } = useExchange()
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds => seconds + 1)
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    seconds < displayTime && (
      <AnimatePresence>
        <motion.div
          initial={{ zIndex: -1, opacity: 0, y: 50 }}
          animate={{ zIndex: 1, opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          key={index}
          id={index}
          className={notificationStyles.toast}>
          {icon === 'bookmark' ? (
            <span className={notificationStyles.bookmark}></span>
          ) : (
            <span>{icon}</span>
          )}
          <span className={notificationStyles.msg}>
            {msg1} {product} {msg2} {convertToCurrency(price)}
          </span>
          <Link
            href={linkHref}
            onClick={() => {
              dispatch(removeNotification(index))
            }}>
            <a className={notificationStyles.link}>{linkText}</a>
          </Link>
          <button
            onClick={() => {
              dispatch(removeNotification(index))
            }}
            className={notificationStyles.close}>
            [x]
          </button>
        </motion.div>
      </AnimatePresence>
    )
  )
}

export default Notification
