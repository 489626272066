import { useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '~/redux/actions/modal'

export function useModal() {
  const dispatch = useDispatch()
  const callback = useSelector(state => state.modal.callback)
  const message = useSelector(state => state.modal.message)
  const isOpen = useSelector(state => state.modal.isOpen)

  const openModal = useMemo(
    () => bindActionCreators(actions.openModal, dispatch),
    [dispatch]
  )

  const closeModal = useMemo(
    () => bindActionCreators(actions.closeModal, dispatch),
    [dispatch]
  )

  return {
    isOpen,
    callback,
    message,
    openModal,
    closeModal
  }
}
