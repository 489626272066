// AUTH
export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST'
export const AUTH_LOGIN_RESPONSE = 'AUTH_LOGIN_RESPONSE'
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR'
export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST'
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS'
export const AUTH_LOGOUT_ERROR = 'AUTH_LOGOUT_ERROR'
export const AUTH_IDENTIFY_REQUEST = 'AUTH_IDENTIFY_REQUEST'
export const AUTH_IDENTIFY_SUCCESS = 'AUTH_IDENTIFY_SUCCESS'
export const AUTH_IDENTIFY_ERROR = 'AUTH_IDENTIFY_ERROR'
export const AUTH_REGISTER_REQUEST = 'AUTH_REGISTER_REQUEST'
export const AUTH_REGISTER_SUCCESS = 'AUTH_REGISTER_SUCCESS'
export const AUTH_REGISTER_ERROR = 'AUTH_REGISTER_ERROR'
export const AUTH_RECOVER_ERROR = 'AUTH_REGISTER_ERROR'
export const AUTH_RECOVER_REQUEST = 'AUTH_RECOVER_REQUEST'
export const AUTH_RECOVER_SUCCESS = 'AUTH_RECOVER_SUCCESS'
export const AUTH_RESET_PASSWORD_ERROR = 'AUTH_RESET_PASSWORD_ERROR'
export const AUTH_RESET_PASSWORD_REQUEST = 'AUTH_RESET_PASSWORD_REQUEST'
export const AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS'
// SESSION
export const CLEAR_SESSION = 'CLEAR_SESSION'
export const INITIALIZE_SESSION_ID_FROM_STORAGE =
  'INITIALIZE_SESSION_ID_FROM_STORAGE'
// CART
export const LOAD_CART_REQUEST = 'LOAD_CART_REQUEST'
export const LOAD_CART_SUCCESS = 'LOAD_CART_SUCCESS'
export const LOAD_CART_ERROR = 'LOAD_CART_ERROR'
export const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST'
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS'
export const ADD_TO_CART_ERROR = 'ADD_TO_CART_ERROR'
export const MODIFY_CART_QUANTITY_REQUEST = 'MODIFY_CART_QUANTITY_REQUEST'
export const MODIFY_CART_QUANTITY_SUCCESS = 'MODIFY_CART_QUANTITY_SUCCESS'
export const MODIFY_CART_QUANTITY_ERROR = 'MODIFY_CART_QUANTITY_ERROR'
export const REMOVE_FROM_CART_REQUEST = 'REMOVE_FROM_CART_REQUEST'
export const REMOVE_FROM_CART_SUCCESS = 'REMOVE_FROM_CART_SUCCESS'
export const REMOVE_FROM_CART_ERROR = 'REMOVE_FROM_CART_ERROR'
export const FORGET_CART = 'FORGET_CART'
export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST'
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS'
export const CHECKOUT_ERROR = 'CHECKOUT_ERROR'
export const FINALIZE_CHECKOUT_REQUEST = 'FINALIZE_CHECKOUT_REQUEST'
export const FINALIZE_CHECKOUT_SUCCESS = 'FINALIZE_CHECKOUT_SUCCESS'
export const FINALIZE_CHECKOUT_ERROR = 'FINALIZE_CHECKOUT_ERROR'
export const ADD_PROMO_CODE_REQUEST = 'ADD_PROMO_CODE_REQUEST'
export const ADD_PROMO_CODE_SUCCESS = 'ADD_PROMO_CODE_SUCCESS'
export const ADD_PROMO_CODE_ERROR = 'ADD_PROMO_CODE_ERROR'
export const REMOVE_PROMO_CODE_REQUEST = 'REMOVE_PROMO_CODE_REQUEST'
export const REMOVE_PROMO_CODE_SUCCESS = 'REMOVE_PROMO_CODE_SUCCESS'
export const REMOVE_PROMO_CODE_ERROR = 'REMOVE_PROMO_CODE_ERROR'
export const APPLY_GIFT_CARD_REQUEST = 'APPLY_GIFT_CARD_REQUEST'
export const APPLY_GIFT_CARD_SUCCESS = 'APPLY_GIFT_CARD_SUCCESS'
export const APPLY_GIFT_CARD_ERROR = 'APPLY_GIFT_CARD_ERROR'
export const REMOVE_GIFT_CARD_REQUEST = 'REMOVE_GIFT_CARD_REQUEST'
export const REMOVE_GIFT_CARD_SUCCESS = 'REMOVE_GIFT_CARD_SUCCESS'
export const REMOVE_GIFT_CARD_ERROR = 'REMOVE_GIFT_CARD_ERROR'
export const TRACK_CLICK_PRODUCT = 'TRACK_CLICK_PRODUCT'
export const TRACK_CLICK_COLLECTION = 'TRACK_CLICK_COLLECTION'
export const TRACK_CREATE_STOCK_REQUEST = 'TRACK_CREATE_STOCK_REQUEST'
export const TRACK_VIEW_CART = 'TRACK_VIEW_CART'
export const INITIALIZE_STATE_FROM_STORAGE = 'INITIALIZE_STATE_FROM_STORAGE'
export const MODIFY_CART_REQUEST = 'MODIFY_CART_REQUEST'
export const MODIFY_CART_SUCCESS = 'MODIFY_CART_SUCCESS'
export const MODIFY_CART_ERROR = 'MODIFY_CART_ERROR'
export const ASSOCIATE_CART_WITH_USER_REQUEST =
  'ASSOCIATE_CART_WITH_USER_REQUEST'
export const ASSOCIATE_CART_WITH_USER_SUCCESS =
  'ASSOCIATE_CART_WITH_USER_SUCCESS'
export const ASSOCIATE_CART_WITH_USER_ERROR = 'ASSOCIATE_CART_WITH_USER_ERROR'
// USER
export const LOAD_USER_REQUEST = 'LOAD_USER_REQUEST'
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS'
export const LOAD_USER_ERROR = 'LOAD_USER_ERROR'
export const IDCHECK_USER = 'IDCHECK_USER'
export const IDCHECK_USER_SUCCESS = 'IDCHECK_USER_SUCCESS'
export const USER_VERIFIED = 'USER_VERIFIED'
export const VERIFY_USER_ERROR = 'VERIFY_USER_ERROR'
export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS'
export const MODIFY_USER_PHONE = 'MODIFY_USER_PHONE'
export const MODIFY_USER_NAME = 'MODIFY_USER_NAME'
export const CREATE_USER_ADDRESS_REQUEST = 'CREATE_USER_ADDRESS_REQUEST'
export const CREATE_USER_ADDRESS_SUCCESS = 'CREATE_USER_ADDRESS_SUCCESS'
export const CREATE_USER_ADDRESS_ERROR = 'CREATE_USER_ADDRESS_ERROR'
export const MODIFY_USER_ADDRESS_REQUEST = 'MODIFY_USER_ADDRESS_REQUEST'
export const MODIFY_USER_ADDRESS_SUCCESS = 'MODIFY_USER_ADDRESS_SUCCESS'
export const MODIFY_USER_ADDRESS_ERROR = 'MODIFY_USER_ADDRESS_ERROR'
export const REMOVE_USER_ADDRESS_REQUEST = 'REMOVE_USER_ADDRESS_REQUEST'
export const REMOVE_USER_ADDRESS_SUCCESS = 'REMOVE_USER_ADDRESS_SUCCESS'
export const REMOVE_USER_ADDRESS_ERROR = 'REMOVE_USER_ADDRESS_ERROR'
export const MODIFY_USER_DEFAULT_ADDRESS_REQUEST =
  'MODIFY_USER_DEFAULT_ADDRESS_REQUEST'
export const MODIFY_USER_DEFAULT_ADDRESS_SUCCESS =
  'MODIFY_USER_DEFAULT_ADDRESS_SUCCESS'
export const MODIFY_USER_DEFAULT_ADDRESS_ERROR =
  'MODIFY_USER_DEFAULT_ADDRESS_ERROR'
export const CHECK_USER_STRIPE_ACCOUNT = 'CHECK_USER_STRIPE_ACCOUNT'
export const CHECK_USER_STRIPE_PAYMENT = 'CHECK_USER_STRIPE_PAYMENT'
export const CHECK_USER_STRIPE_CUSID = 'CHECK_USER_STRIPE_CUSID'
export const USER_AGREED_TO_TERMS = 'USER_AGREED_TO_TERMS'
export const USER_AGREE_TO_TERMS_SUCCESS = 'USER_AGREE_TO_TERMS_SUCCESS'
export const USER_AGREE_TO_TERMS_ERROR = 'USER_AGREE_TO_TERMS_ERROR'
export const ADD_WHATSAPP_TO_PROFILE = 'ADD_WHATSAPP_TO_PROFILE'
export const ADD_WHATSAPP_TO_PROFILE_SUCCESS = 'ADD_WHATSAPP_TO_PROFILE_SUCCESS'
export const ADD_WHATSAPP_TO_PROFILE_ERROR = 'ADD_WHATSAPP_TO_PROFILE_ERROR'
export const USER_HAS_NEWSLETTER_SUBSCRIPTION =
  'USER_HAS_NEWSLETTER_SUBSCRIPTION'
// BASTA
export const GET_AUCTION_REQUEST = 'GET_AUCTION_REQUEST'
export const GET_AUCTION_SUCCESS = 'GET_AUCTION_SUCCESS'
export const GET_AUCTION_ERROR = 'GET_AUCTION_ERROR'
export const GET_AUCTIONS_REQUEST = 'GET_AUCTIONS_REQUEST'
export const GET_AUCTIONS_SUCCESS = 'GET_AUCTIONS_SUCCESS'
export const GET_AUCTIONS_ERROR = 'GET_AUCTIONS_ERROR'
export const BID_REQUEST = 'BID_REQUEST'
export const BID_SUCCESS = 'BID_SUCCESS'
export const BID_ERROR = 'BID_ERROR'
export const SET_MAX_BID = 'SET_MAX_BID'
export const SET_BIDDING_STEP = 'SET_BIDDING_STEP'
export const CLIENT_SALE_REQUEST = 'CLIENT_SALE_REQUEST'
export const CLIENT_SALE_SUCCESS = 'CLIENT_SALE_SUCCESS'
export const CLIENT_SALE_ERROR = 'CLIENT_SALE_ERROR'
export const ITEM_UPDATE_REQUEST = 'ITEM_UPDATE_REQUEST'
export const ITEM_UPDATE_SUCCESS = 'ITEM_UPDATE_SUCCESS'
export const ITEM_UPDATE_ERROR = 'ITEM_UPDATE_ERROR'
export const SET_CLIENT_INSTANCE = 'SET_CLIENT_INSTANCE'
export const SALE_CLOSED = 'SALE_CLOSED'
export const ADD_ACTIVITY_MESSAGE = 'ADD_ACTIVITY_MESSAGE'
export const REMOVE_ACTIVITY_MESSAGE = 'REMOVE_ACTIVITY_MESSAGE'
export const BASTA_CLIENT_BIDS_REQUEST = 'BASTA_CLIENT_BIDS_REQUEST'
export const BASTA_CLIENT_BIDS_SUCCESS = 'BASTA_CLIENT_BIDS_SUCCESS'
export const BASTA_CLIENT_BIDS_ERROR = 'BASTA_CLIENT_BIDS_ERROR'
export const BASTA_CLIENT_ACTIVE_AUCTIONS_REQUEST =
  'BASTA_CLIENT_ACTIVE_AUCTIONS_REQUEST'
export const BASTA_CLIENT_ACTIVE_AUCTIONS_SUCCESS =
  'BASTA_CLIENT_ACTIVE_AUCTIONS_SUCCESS'
export const BASTA_CLIENT_ACTIVE_AUCTIONS_ERROR =
  'BASTA_CLIENT_ACTIVE_AUCTIONS_ERROR'
// BOOKMARKS
export const SET_BOOKMARK = 'SET_BOOKMARK'
export const LOAD_BOOKMARKS = 'LOAD_BOOKMARKS'
export const INITIALIZE_BOOKMARKS_FROM_STORAGE =
  'INITIALIZE_BOOKMARKS_FROM_STORAGE'
export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
// Newsletter
export const SET_NEWSLETTER_HIDE_MODAL = 'SET_NEWSLETTER_HIDE_MODAL'
export const INITIALIZE_NEWSLETTER_FROM_STORAGE =
  'INITIALIZE_NEWSLETTER_FROM_STORAGE'
// NOTIFICATION
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'REMOVE NOTIFICATION'
export const REMOVE_NOTIFICATIONS = 'REMOVE NOTIFICATIONS'
// OVERLAY
export const TOGGLE_VERIFICATION_OVERLAY = 'TOGGLE_VERIFICATION_OVERLAY'
export const TOGGLE_CONTENT_OVERLAY = 'TOGGLE_CONTENT_OVERLAY'
export const TOGGLE_ALERT_OVERLAY = 'TOGGLE_ALERT_OVERLAY'
// COMMENTS
export const ADD_COMMENT_REQUEST = 'ADD_COMMENT_REQUEST'
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS'
export const ADD_COMMENT_ERROR = 'ADD_COMMENT_ERROR'
export const DELETE_COMMENT_REQUEST = 'DELETE_COMMENT_REQUEST'
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS'
export const DELETE_COMMENT_ERROR = 'DELETE_COMMENT_ERROR'
export const FLAG_COMMENT_REQUEST = 'FLAG_COMMENT_REQUEST'
export const FLAG_COMMENT_SUCCESS = 'FLAG_COMMENT_SUCCESS'
export const FLAG_COMMENT_ERROR = 'FLAG_COMMENT_ERROR'
export const LOAD_COMMENTS_REQUEST = 'LOAD_COMMENTS_REQUEST'
export const LOAD_COMMENTS_SUCCESS = 'LOAD_COMMENTS_SUCCESS'
export const LOAD_COMMENTS_ERROR = 'LOAD_COMMENTS_ERROR'
export const CLEAR_COMMENTS = 'CLEAR_COMMENTS'
// AMITY
export const AMITY_CHECK_PERMISSIONS_REQUEST = 'AMITY_CHECK_PERMISSIONS_REQUEST'
export const AMITY_CHECK_PERMISSIONS_SUCCESS = 'AMITY_CHECK_PERMISSIONS_SUCCESS'
export const AMITY_CHECK_PERMISSIONS_ERROR = 'AMITY_CHECK_PERMISSIONS_ERROR'
// FILTERS
export const ADD_FILTER = 'ADD_FILTER'
export const REMOVE_FILTER = 'REMOVE FILTER'
export const REMOVE_FILTERS = 'REMOVE FILTERS'
export const GET_KLAVIYO_PROFILE = 'GET_KLAVIYO_PROFILE'
export const GET_KLAVIYO_PROFILE_SUCCESS = 'GET_KLAVIYO_PROFILE_SUCCESS'

// Status
const status = {
  Idle: 'idle',
  Initial: 'initial',
  Failed: 'failed',
  Loading: 'loading',
  Succeeded: 'succeeded'
}
// Bidding Steps
const biddingSteps = {
  PlaceBid: 'placeBid',
  ChooseBid: 'chooseBid',
  ConfirmBid: 'confirmBid',
  LoginVerifyUser: 'loginVerifyUser',
  BidPlaced: 'bidPlaced',
  Enquire: 'enquire',
  ConfirmOffer: 'confirmOffer',
  OfferSubmitted: 'offerSubmitted',
  EnquirySuccess: 'enquirySuccess'
}

export const BiddingSteps = biddingSteps
export const AuthStatus = status
export const UserStatus = status
export const AmityStatus = status
