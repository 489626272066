import {
  ADD_WHATSAPP_TO_PROFILE,
  ADD_WHATSAPP_TO_PROFILE_ERROR,
  ADD_WHATSAPP_TO_PROFILE_SUCCESS,
  GET_KLAVIYO_PROFILE,
  GET_KLAVIYO_PROFILE_SUCCESS
} from '../types'

export const addWhatsAppToProfile = () => ({
  type: ADD_WHATSAPP_TO_PROFILE
})

export const getKlaviyoProfile = () => ({
  type: GET_KLAVIYO_PROFILE
})

export const getKlaviyoProfileSuccess = payload => ({
  type: GET_KLAVIYO_PROFILE_SUCCESS,
  payload
})

export const addWhatsAppToProfileSuccess = payload => ({
  type: ADD_WHATSAPP_TO_PROFILE_SUCCESS,
  payload
})

export const addWhatsAppToProfileError = error => ({
  type: ADD_WHATSAPP_TO_PROFILE_ERROR,
  payload: error,
  error: true
})

export const doAddWhatAppToProfile =
  ({ whatsAppNumber, prefersWhatsApp }) =>
  async (dispatch, getState) => {
    try {
      // Grab auth token
      const { user } = getState()
      console.log('dbg checkUserTermsAgreement: ', user.data.id)
      // Query ShopifyAdmin to add tag
      await fetch(`/api/customer/metafields`, {
        method: 'POST',
        body: JSON.stringify({
          customerId: user.data.id,
          namespace: 'whatsapp',
          value: {
            whatsAppNumber,
            prefersWhatsApp
          }
        })
      })
      // Dispatch action with status
      dispatch(addWhatsAppToProfileSuccess(true))
    } catch (error) {
      dispatch(addWhatsAppToProfileError(error))
      throw error
    }
  }
