import { ADD_FILTER } from '~/redux/actions/types'
import { REMOVE_FILTER } from '~/redux/actions/types'
import { REMOVE_FILTERS } from '~/redux/actions/types'
import initialState from '~/redux/store/initial-state'

const filters = (state = initialState.filters, action) => {
  switch (action.type) {
    case ADD_FILTER:
      return [...state, action.payload]

    case REMOVE_FILTER:
      return [...state.filter(element => element !== action.payload)]

    case REMOVE_FILTERS:
      return []

    default:
      return state
  }
}

export default filters
