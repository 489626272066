import {
  BOOKMARK_PRODUCT_FRAGMENT,
  PRODUCT_FRAGMENT,
  PRODUCT_LOT_FRAGMENT,
  PRODUCT_SUMMARY_FRAGMENT,
  VARIANT_FRAGMENT,
  PRODUCT_ALP_FRAGMENT
} from './fragment'
import { fetchGraphQL } from '../api'

export const getProducts = async ({ locale }) => {
  locale = !locale || locale.includes('en') ? 'en-US' : locale

  // Pagination Loop to fetch all the products
  let limit = 50
  let skip = 0
  let step = 50
  const maxLimit = 1000
  let responseTotalItemsCount = null
  let products = []
  do {
    const query = `query {
      productCollection(locale: "${locale}", limit: ${limit}, skip: ${skip}) {
        total,
        items {
          ${PRODUCT_SUMMARY_FRAGMENT}
        }
      }
    }`

    const response = await fetchGraphQL(query)

    // Concatenate the response data with the products array
    if (response.data.productCollection.items) {
      products = products.concat(response.data.productCollection.items)
    }

    // Set the total items count
    if (!responseTotalItemsCount) {
      responseTotalItemsCount = response.data.productCollection.total
    }

    // Break the loop if the limit is greater than the max limit
    if (skip > maxLimit) {
      break
    }

    skip += step
  } while (products.length < responseTotalItemsCount)

  return products
}

export const getProduct = async ({ slug, locale }) => {
  locale = !locale || locale.includes('en') ? 'en-US' : locale
  const query = `{
    productCollection(locale: "${locale}", where: {slug: "${slug}" }, limit: 1)  {
      items {
        ${PRODUCT_FRAGMENT}
      }
    }
  }`

  const response = await fetchGraphQL(query)
  const product = response.data.productCollection.items
    ? response.data.productCollection.items[0]
    : null

  if (product) {
    const variants = product.variantsCollection.items
    const variantsItems = []
    for (const variant of variants) {
      const variantObj = await getVariant({ id: variant.sys.id })
      if (variantObj) {
        variantsItems.push(variantObj)
      }
    }
    product.variantsCollection.items = variantsItems
  }

  return product
}

export const getVariant = async ({ id, locale }) => {
  locale = !locale || locale.includes('en') ? 'en-US' : locale
  const query = `query {
    variantCollection(locale: "${locale}", where: { sys: { id: "${id}" } }, limit: 1) {
      items {
        ${VARIANT_FRAGMENT}
      }
    }
  }`

  const response = await fetchGraphQL(query)

  const variant = response.data.variantCollection.items
    ? response.data.variantCollection.items[0]
    : null

  return variant
}

export const getProductsBySlug = async ({ slugs, locale }) => {
  const slugsStr = slugs.map(x => `"${x}"`).join(',')
  locale = !locale || locale.includes('en') ? 'en-US' : locale
  const query = `query {
    productCollection(locale: "${locale}", where: {slug_in: [${slugsStr}]}) {
      items {
        ${BOOKMARK_PRODUCT_FRAGMENT}
      }
    }
  }`

  const response = await fetchGraphQL(query)

  const products = response.data.productCollection.items
    ? response.data.productCollection.items
    : []

  return products
}

export const getProductsByTag = async ({ locale, tags }) => {
  const tagsStr = tags.map(x => `"${x}"`).join(',')
  locale = !locale || locale.includes('en') ? 'en-US' : locale
  const query = `query {
      productCollection(locale: "${locale}") (
          where: {
              contentfulMetadata: {
                  tags_exists: true
                  tags: {
                      id_contains_some: [${tagsStr}]
                  }
              }
          }
      ) {
          items {
              ${PRODUCT_SUMMARY_FRAGMENT}
          }
      }
  }`

  const response = await fetchGraphQL(query)

  return response.data.productCollection.items
    ? response.data.productCollection.items
    : []
}

export const getProductByBastaItemId = async (bastaItemId, locale) => {
  locale = !locale || locale.includes('en') ? 'en-US' : locale
  const query = `query {
      productCollection(
      locale: "${locale}"
          where: {
              bastaItemId: "${bastaItemId}"
          },
          limit: 1
      ) {
          items {
              ${PRODUCT_SUMMARY_FRAGMENT}
          }
      }
  }`

  const response = await fetchGraphQL(query)

  return response.data.productCollection.items
    ? response.data.productCollection.items
    : []
}

export const getLotProducts = async ({ locale }) => {
  locale = !locale || locale.includes('en') ? 'en-US' : locale

  // Pagination Loop to fetch all the products
  let limit = 50
  let skip = 0
  let step = 50
  const maxLimit = 1000
  let responseTotalItemsCount = null
  let products = []

  do {
    const query = `query {
        productCollection(locale: "${locale}", limit: ${limit}, skip: ${skip}, where: { slug_exists: true AND: {contentfulMetadata: {
            tags_exists: true
        }}}) {
            total,
            items {
                ${PRODUCT_LOT_FRAGMENT}
            }
        }
    }`

    const response = await fetchGraphQL(query)

    // Concatenate the response data with the products array
    if (response.data.productCollection.items) {
      products = products.concat(response.data.productCollection.items)
    }

    // Set the total items count
    if (!responseTotalItemsCount) {
      responseTotalItemsCount = response.data.productCollection.total
    }

    // Break the loop if the limit is greater than the max limit
    if (skip > maxLimit) {
      break
    }

    skip += step
  } while (products.length < responseTotalItemsCount)

  return products
}

export const getAlpProduct = async ({ locale, slug }) => {
  locale = !locale || locale.includes('en') ? 'en-US' : locale
  const query = `query {
    productCollection(locale: "${locale}", where: {slug: "${slug}" }, limit: 1)  {
      items {
        ${PRODUCT_ALP_FRAGMENT}
      }
    }
  }`

  const response = await fetchGraphQL(query)

  const product = response.data.productCollection.items
    ? response.data.productCollection.items[0]
    : null

  if (product) {
    const variants = product.variantsCollection.items
    const variantsItems = []
    for (const variant of variants) {
      const variantObj = await getVariant({ id: variant.sys.id })
      if (variantObj) {
        variantsItems.push(variantObj)
      }
    }
    product.variantsCollection.items = variantsItems
  }

  return product
}
