import {
  LOAD_COMMENTS_REQUEST,
  LOAD_COMMENTS_SUCCESS,
  LOAD_COMMENTS_ERROR
} from '~/redux/actions/types'

export const loadCommentsRequest = () => ({
  type: LOAD_COMMENTS_REQUEST
})

export const loadCommentsSuccess = payload => ({
  type: LOAD_COMMENTS_SUCCESS,
  payload
})

export const loadCommentsError = error => ({
  type: LOAD_COMMENTS_ERROR,
  error: true,
  payload: {
    error
  }
})

export const loadComments =
  ({ postId, before, parentId }) =>
  async (dispatch, getState) => {
    dispatch(loadCommentsRequest())
    const { user } = getState()
    try {
      const params = new URLSearchParams({
        postId,
        userId: user.data.id,
        displayName: user.data.displayName,
        ...{ before },
        ...{ parentId }
      })
      const response = await fetch('/api/amity/comments?' + params)
      const comments = await response.json()
      dispatch(loadCommentsSuccess({ comments, ...{ parentId } }))
    } catch (error) {
      dispatch(loadCommentsError(error))
      throw error
    }
  }
