import {
  REMOVE_USER_ADDRESS_REQUEST,
  REMOVE_USER_ADDRESS_SUCCESS,
  REMOVE_USER_ADDRESS_ERROR
} from '~/redux/actions/types'

export const removeUserAddressRequest = () => ({
  type: REMOVE_USER_ADDRESS_REQUEST
})

export const removeUserAddressSuccess = payload => ({
  type: REMOVE_USER_ADDRESS_SUCCESS,
  payload
})

export const removeUserAddressError = error => ({
  type: REMOVE_USER_ADDRESS_ERROR,
  payload: error,
  error: true
})

export const removeUserAddress =
  addressId =>
  async (dispatch, getState, { api }) => {
    dispatch(removeUserAddressRequest())
    const { auth } = getState()

    try {
      const response = await api.removeUserAddress(auth.token, addressId)
      dispatch(removeUserAddressSuccess(response))
    } catch (error) {
      dispatch(removeUserAddressError(error))
      throw error
    }
  }
