import { SET_BOOKMARK } from '~/redux/actions/types'

const setLocalBookmark = (productId, value) => {
  return {
    type: SET_BOOKMARK,
    payload: {
      productId: productId,
      value: value
    }
  }
}

export const setBookmark = (productId, value) => async (dispatch, getState) => {
  await dispatch(setLocalBookmark(productId, value))

  const { user } = getState()
  fetch('/api/bookmarks', {
    method: 'POST',
    body: JSON.stringify({
      customerId: user.data.id,
      product: productId,
      value: value
    })
  }).catch(err => {
    dispatch(setLocalBookmark(productId, !value))
    throw new Error(err)
  })
}
