import Link from 'next/link'
import mobileFooterStyles from './MobileFooter.module.scss'

const MobileFooter = ({ toggleSearchDrawer }) => {
  return (
    <div className={mobileFooterStyles.mobileFooter}>
      <ul role="menu" className={mobileFooterStyles.list}>
        <li className={mobileFooterStyles.list_item}>
          <button
            type="button"
            onClick={() => {
              toggleSearchDrawer()
            }}>
            Search
          </button>
        </li>
        <li
          className={mobileFooterStyles.seperator}
          aria-hidden="true"
          role="presentation"></li>
        <li className={mobileFooterStyles.list_item}>
          <Link href="/support">
            <a>Support</a>
          </Link>
        </li>
      </ul>
    </div>
  )
}
export default MobileFooter
