import Link from 'next/link'
import { Fragment, useEffect } from 'react'
import styles from '../../Global/AuctionWonOverlay/AuctionWonOverlay.module.scss'
import dynamic from 'next/dynamic'
import ResponsiveImage from '~/components/Common/ResponsiveImage'
import storage from '~/services/storage'
const Carousel = dynamic(() => import('~/components/Product/Carousel'))

const Overlay = ({
  name,
  status,
  overlayClass = '',
  toggle,
  logo = false,
  logoFixed = true,
  close = true,
  salesWon,
  salesShown
}) => {
  const minDesktopWidth = 992
  useEffect(() => {
    status
      ? document.body.classList.add(`${name}OverlayActive`)
      : document.body.classList.remove(`${name}OverlayActive`)
    return () => {
      document.body.classList.remove(`${name}OverlayActive`)
    }
  }, [name, status])

  return status ? (
    <section className={`${styles.overlay} ${overlayClass}`}>
      <Carousel
        className={styles.carousel}
        splideOptions={{
          arrows: salesWon.length > 1,
          breakpoints: {
            [minDesktopWidth - 1]: { arrows: false }
          }
        }}>
        {salesWon
          .filter(saleWon => saleWon.product.invoiceUrl)
          .map(saleWon => {
            return (
              <Fragment key={saleWon.saleClosedEvent.id}>
                {saleWon.product.name && (
                  <p
                    className={
                      styles.title
                    }>{`Congrats, You Won ${saleWon.product.name}`}</p>
                )}

                {logo && (
                  <Link href="/">
                    <a
                      className={`icon icon--logo ${styles.logo} ${
                        logoFixed ? styles.logoFixed : styles.logoAbsolute
                      }`}></a>
                  </Link>
                )}
                <div
                  onClick={() => {
                    storage.setItem(
                      'salesWon',
                      JSON.stringify([
                        ...salesShown,
                        ...salesWon.map(saleWon => saleWon.saleClosedEvent.id)
                      ])
                    )
                  }}
                  className={styles.content}>
                  {/* eslint-disable-next-line @next/next/link-passhref */}
                  <Link
                    href={
                      saleWon.product.isExcludedFromCheckout
                        ? '/'
                        : saleWon.product.invoiceUrl
                    }>
                    <div style={{ cursor: 'pointer' }}>
                      <ResponsiveImage
                        maxWidth={500}
                        image={saleWon.product.mainImage}
                      />
                      <h2
                        className={`font--heading-2 ${
                          saleWon.product.isExcludedFromCheckout ? 'hide' : ''
                        }`}>
                        Proceed to Checkout
                      </h2>
                    </div>
                  </Link>
                </div>
              </Fragment>
            )
          })}
      </Carousel>
      {close && (
        <button className={styles.close} onClick={toggle}>
          <span className="icon icon--close"></span>
        </button>
      )}
    </section>
  ) : null
}

export default Overlay
