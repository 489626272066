import { useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '~/redux/actions/newsletter'

export function useNewsletterModal() {
  const dispatch = useDispatch()
  const hideModal = useSelector(state => state.newsletter.hideModal)

  const setNewsletterHideModal = useMemo(
    () => bindActionCreators(actions.setNewsletterHideModal, dispatch),
    [dispatch]
  )

  return {
    hideModal,
    setNewsletterHideModal
  }
}
