import { HYDRATE } from 'next-redux-wrapper'

const root = (state = {}, action) => {
  switch (action.type) {
    case HYDRATE:
      console.log('HYDRATE', action)
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default root
