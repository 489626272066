import wrapperStyles from './Wrapper.module.scss'

const AccountWrapper = ({ title, children, close }) => {
  return (
    <section className={wrapperStyles.wrapper}>
      <div className={wrapperStyles.container}>
        <h1 className={wrapperStyles.heading}>{title}</h1>
        {close && (
          <button className={wrapperStyles.close} onClick={close} type="button">
            [x]
          </button>
        )}
        <div className={wrapperStyles.children}>{children}</div>
      </div>
    </section>
  )
}

export default AccountWrapper
