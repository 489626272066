import {
  MODIFY_USER_DEFAULT_ADDRESS_REQUEST,
  MODIFY_USER_DEFAULT_ADDRESS_SUCCESS,
  MODIFY_USER_DEFAULT_ADDRESS_ERROR
} from '~/redux/actions/types'

export const modifyUserDefaultAddressRequest = () => ({
  type: MODIFY_USER_DEFAULT_ADDRESS_REQUEST
})

export const modifyUserDefaultAddressSuccess = payload => ({
  type: MODIFY_USER_DEFAULT_ADDRESS_SUCCESS,
  payload
})

export const modifyUserDefaultAddressError = error => ({
  type: MODIFY_USER_DEFAULT_ADDRESS_ERROR,
  payload: error,
  error: true
})

export const modifyUserDefaultAddress =
  addressId =>
  async (dispatch, getState, { api }) => {
    dispatch(modifyUserDefaultAddressRequest())
    const { auth } = getState()

    try {
      const response = await api.updateUserDefaultAddress(auth.token, addressId)
      dispatch(modifyUserDefaultAddressSuccess(response))
    } catch (error) {
      dispatch(modifyUserDefaultAddressError(error))
      throw error
    }
  }
