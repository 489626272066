import * as React from 'react'
import { default as NextLink } from 'next/link'
import { useRouter } from 'next/router'
import { Link as ThemeUILink } from 'theme-ui'

const Link = ({ href, ...props }) => {
  const { locale } = useRouter()
  return (
    <NextLink href={href} passHref locale={locale}>
      <ThemeUILink {...props} />
    </NextLink>
  )
}

export default Link
