import { LOAD_BOOKMARKS } from '~/redux/actions/types'

export const loadBookmarks = payload => {
  return {
    type: LOAD_BOOKMARKS,
    payload
  }
}

export const loadBookmarksFromShopify = () => async (dispatch, getState) => {
  const { user } = getState()
  const { bookmarks } = await fetch(
    `/api/bookmarks?customerId=${user.data.id}`,
    { headers: { 'Cache-Control': 'no-cache' } }
  ).then(response => response.json())
  dispatch(loadBookmarks({ bookmarks: bookmarks ? JSON.parse(bookmarks) : {} }))
}
