import {
  BASTA_CLIENT_ACTIVE_AUCTIONS_REQUEST,
  BASTA_CLIENT_ACTIVE_AUCTIONS_SUCCESS,
  BASTA_CLIENT_ACTIVE_AUCTIONS_ERROR
} from '~/redux/actions/types'

export const clientSaleRequest = ({ userId }) => {
  return {
    type: BASTA_CLIENT_ACTIVE_AUCTIONS_REQUEST,
    userId: userId
  }
}

export const clientSaleSuccess = clientSales => {
  return {
    type: BASTA_CLIENT_ACTIVE_AUCTIONS_SUCCESS,
    data: clientSales
  }
}

export const clientSaleError = (error, meta = {}) => {
  return {
    type: BASTA_CLIENT_ACTIVE_AUCTIONS_ERROR,
    error: true,
    meta,
    payload: error
  }
}

export const getActiveClientSales = ({ userId }) => {
  const encodedUserId = btoa(userId)
  return async dispatch => {
    try {
      dispatch(clientSaleRequest({ encodedUserId }))
      const sale = await fetch(`/api/basta/clientActiveAuctions`, {
        method: 'POST',
        body: JSON.stringify({ userId: encodedUserId })
      })
      const saleResult = await sale.json()
      dispatch(clientSaleSuccess(saleResult))
    } catch (e) {
      dispatch(clientSaleError(e, { encodedUserId }))
      throw e
    }
  }
}
