import { useState, useEffect } from 'react'
import ReactModal from 'react-modal'

import storage from '~/services/storage'

import styles from './index.module.scss'

const DisabledBidAlert = () => {
  // Bidding Kill Switch
  const disableBiddingFlag = process.env.DISABLE_BIDDING_FLAG
    ? process.env.DISABLE_BIDDING_FLAG === 'true'
    : false

  const [isOpen, setIsOpen] = useState(() => {
    const persistValue = storage.getItem('disable-bidding-flag')
    return persistValue !== null ? JSON.parse(persistValue) : disableBiddingFlag
  })

  useEffect(() => {
    storage.setItem('disable-bidding-flag', JSON.stringify(isOpen))
  }, [isOpen])

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      ariaHideApp={false}
      className={styles.alertModal}
      contentLabel="Bidding Disabled Alert">
      <div className={styles.container}>
        <h1 className={styles.heading}>Technical Issue</h1>
        <div className={styles.children}>
          <p>
            Thank you all so much for your support. Due to the high volume of
            interest, we are experiencing temporary technical issues. The site
            will be back up very shortly. We are so sorry for the inconvenience.
          </p>
          <button
            type="button"
            className="cta cta--purple"
            style={{ width: '100%' }}
            onClick={closeModal}>
            Continue To Site
          </button>
        </div>
      </div>
    </ReactModal>
  )
}
export default DisabledBidAlert
