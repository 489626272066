import { useEffect, useCallback } from 'react'

export default function useVisualViewport(
  { height, width } = {
    height: '--vh',
    width: '--vw'
  }
) {
  const updateViewport = useCallback(
    function updateViewport() {
      if (
        typeof window !== 'undefined' &&
        typeof document !== 'undefined' &&
        typeof document.documentElement !== 'undefined'
      ) {
        const rootStyle = document.documentElement.style

        if (typeof window.visualViewport === 'undefined') {
          rootStyle.setProperty(
            height,
            `${
              Math.max(
                document.documentElement.clientHeight || 0,
                window.innerHeight || 0
              ) / 100
            }px`
          )
          rootStyle.setProperty(
            width,
            `${
              Math.max(
                document.documentElement.clientWidth || 0,
                window.innerWidth || 0
              ) / 100
            }px`
          )
        } else {
          rootStyle.setProperty(
            height,
            `${window.visualViewport.height / 100}px`
          )
          rootStyle.setProperty(width, `${window.visualViewport.width / 100}px`)
        }
      }
    },
    [height, width]
  )

  useEffect(() => {
    if (typeof window !== 'undefined') {
      updateViewport()

      if (typeof window.visualViewport === 'undefined') {
        window.addEventListener('resize', updateViewport)
        return () => window.removeEventListener('resize', updateViewport)
      } else {
        window.visualViewport.addEventListener('resize', updateViewport)
        return () =>
          window.visualViewport.removeEventListener('resize', updateViewport)
      }
    }
  }, [updateViewport])

  return { height, width }
}
