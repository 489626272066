import { MODIFY_USER_NAME } from '~/redux/actions/types'

export const setUserName = payload => ({
  type: MODIFY_USER_NAME,
  payload
})

export const modifyUserName =
  (firstName, lastName) => async (dispatch, getState) => {
    const name = {
      firstName: firstName,
      lastName: lastName
    }
    await dispatch(setUserName(name))

    try {
      const { user } = getState()
      fetch('/api/customer', {
        method: 'POST',
        body: JSON.stringify({
          customerId: user.data.id,
          firstName: firstName,
          lastName: lastName
        })
      }).catch(err => {
        throw new Error(err)
      })
    } catch (error) {
      dispatch(setUserName({ firstName: '', lastName: '' }))
      throw error
    }
  }
