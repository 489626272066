import auth from '~/services/auth'
import { identifyError, identifyRequest, identifySuccess } from './identify'
import { clearSession } from './clear-session'
import { logout } from './logout'

export const getToken = () => async dispatch => {
  try {
    dispatch(identifyRequest())
    const { accessToken } = await auth.getToken()
    dispatch(identifySuccess(accessToken))
    return accessToken
  } catch (error) {
    if (error.message === 'User is not logged in.') {
      dispatch(clearSession())
    } else {
      dispatch(identifyError(error))
      dispatch(logout())
    }
    throw error
  }
}
