import { SET_MAX_BID } from '~/redux/actions/types'

export const setMaxBid = ({ maxBid, itemId }) => {
  return async dispatch => {
    dispatch(setMaxBidAction({ maxBid, itemId }))
  }
}

export const setMaxBidAction = ({ maxBid, itemId }) => {
  return {
    type: SET_MAX_BID,
    data: { maxBid, itemId }
  }
}
