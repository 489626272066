import {
  MODIFY_USER_ADDRESS_REQUEST,
  MODIFY_USER_ADDRESS_SUCCESS,
  MODIFY_USER_ADDRESS_ERROR,
} from '~/redux/actions/types'
import { getKlaviyoProfileSuccess } from '~/redux/actions/user/klaviyo'

export const modifyUserAddressRequest = () => ({
  type: MODIFY_USER_ADDRESS_REQUEST
})

export const modifyUserAddressSuccess = payload => ({
  type: MODIFY_USER_ADDRESS_SUCCESS,
  payload
})

export const modifyUserAddressError = error => ({
  type: MODIFY_USER_ADDRESS_ERROR,
  payload: error,
  error: true
})

export const modifyUserAddress =
  (
    addressId,
    address,
    isDefaultAddress = false,
    prefersWhatsApp,
    whatsAppNumber,
    locale
  ) =>
  async (dispatch, getState, { api }) => {
    dispatch(modifyUserAddressRequest())
    const { auth, user } = getState()

    try {
      let klaviyoResponse
      if (user?.data?.klaviyoProfile?.id) {
        klaviyoResponse = await fetch(
          `/api/klaviyo/profile?id=${user.data.klaviyoProfile.id}`,
          {
            method: 'PATCH',
            body: JSON.stringify({
              email: user.data.email,
              first_name: user.data.firstName,
              last_name: user.data.lastName,
              whatsAppNumber,
              prefersWhatsApp,
              locale
            })
          }
        )
      } else {
        klaviyoResponse = await fetch(`/api/klaviyo/profile`, {
          method: 'POST',
          body: JSON.stringify({
            email: user.data.email,
            first_name: user.data.firstName,
            last_name: user.data.lastName,
            whatsAppNumber,
            prefersWhatsApp,
            locale
          })
        })
      }
      const klaviyoResponseJSON = await klaviyoResponse.json()
      const response = await api.updateUserAddress(
        auth.token,
        addressId,
        address,
        isDefaultAddress
      )
      dispatch(modifyUserAddressSuccess(response))
      dispatch(getKlaviyoProfileSuccess(klaviyoResponseJSON.payload))
    } catch (error) {
      dispatch(modifyUserAddressError(error))
      throw error
    }
  }
