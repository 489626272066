import { useState } from 'react'
import { Controller } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'

import 'react-phone-input-2/lib/plain.css'
import style from './index.module.scss'

const DynamicPhone = ({
  name,
  control,
  autoFocus = true,
  errors,
  disabled,
  placeholder,
  rules
}) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <div
      className={`${style.wrapper} ${isActive ? style.active : ''} ${
        errors[name] ? style.error : ''
      }`}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          field: { onChange, value, name },
          fieldState: { error }
        }) => (
          <>
            <PhoneInput
              enableSearch
              value={value}
              inputProps={{
                name: name,
                autoFocus
              }}
              countryCodeEditable={false}
              country={'us'}
              placeholder={placeholder}
              searchPlaceholder="Search"
              disabled={disabled}
              buttonClass={`${style.button} ${disabled ? style.disabled : ''}`}
              searchClass={`default ${style.search}`}
              dropdownClass={style.dropdown}
              inputClass={`default ${style.input} ${
                disabled ? style.disabled : ''
              }`}
              onChange={onChange}
              onFocus={() => setIsActive(true)}
              onBlur={() => setIsActive(false)}
            />
            {error && (
              <p role="alert" className={style.errorMessage}>
                {error.message}
              </p>
            )}
          </>
        )}
      />
    </div>
  )
}
export default DynamicPhone
