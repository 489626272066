import {
  REMOVE_PROMO_CODE_ERROR,
  REMOVE_PROMO_CODE_REQUEST,
  REMOVE_PROMO_CODE_SUCCESS
} from '~/redux/actions/types'
import { getAllCartPromotionsForDisplay } from '~/utils/promotions'
import { getOrLoadCart } from './get-or-load-cart'

export const removePromoCodeRequest = promoCode => {
  return {
    type: REMOVE_PROMO_CODE_REQUEST,
    promoCode
  }
}

export const removePromoCodeSuccess = (cart, promoCode, promoAmount) => {
  return {
    type: REMOVE_PROMO_CODE_SUCCESS,
    data: cart,
    promoCode,
    promoAmount
  }
}

export const removePromoCodeError = error => {
  return {
    type: REMOVE_PROMO_CODE_ERROR,
    error: true,
    payload: error
  }
}

export const removePromoCode =
  promoCode =>
  async (dispatch, getState, { api }) => {
    try {
      dispatch(removePromoCodeRequest(promoCode))

      const { data: cart } = await getOrLoadCart(dispatch, getState)

      // we only support one promotion per order for now
      const promotions = getAllCartPromotionsForDisplay(cart)
      const promoAmount = promotions ? promotions[0].amount : 0

      const updatedCart = await api.removePromoCode(cart.id, promoCode)

      dispatch(removePromoCodeSuccess(updatedCart, promoCode, promoAmount))
    } catch (error) {
      dispatch(removePromoCodeError(error))
      throw error
    }
  }
