/**
 * ! Important
 *  Keep these values in sync:
 * - `deviceSizes` in `next.config.js`
 * - `deviceSizes` in `utils/generate-sizes`
 *
 * ! Info
 * - `sizes` prop describes how wide the image will be at different breakpoints.
 * - `sizes` prop works with `srcSet` to download optimal image.
 * - `sizes` prop is affected by `devicePixelRatio` of the user's device.
 *
 * ! Default
 * Browser loads the image that is nearest to `viewportwidth` * `devicePixelRatio`.
 *
 * ! Pass
 * - `sizes` to images with the highest width value being the max width an image can have on your site.
 */
const deviceSizes = [320, 480, 568, 640, 750, 828, 960, 1080, 1200, 1440, 1600]
const deviceSizesMax = Math.max(...deviceSizes)

/**
 * Creates string query for NextImage `sizes` prop
 */
const generateSizes = (maxLimit = deviceSizesMax) => {
  const sizes = [...deviceSizes.filter(v => v < maxLimit), maxLimit]
  return sizes
    .map((v, i) => {
      return i < sizes.length - 1 ? ` (max-width: ${v}px) ${v}px` : ` ${v}px`
    })
    .join()
}

export default generateSizes
