import {
  AUTH_IDENTIFY_ERROR,
  AUTH_IDENTIFY_REQUEST,
  AUTH_IDENTIFY_SUCCESS
} from '~/redux/actions/types'

export const identifyRequest = email => {
  return {
    type: AUTH_IDENTIFY_REQUEST,
    payload: {
      email
    }
  }
}

export const identifySuccess = token => {
  return {
    type: AUTH_IDENTIFY_SUCCESS,
    payload: {
      token
    }
  }
}

export const identifyError = (error, meta = {}) => {
  return {
    type: AUTH_IDENTIFY_ERROR,
    error: true,
    meta,
    payload: {
      error
    }
  }
}
