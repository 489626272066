import {
  AUTH_RESET_PASSWORD_ERROR,
  AUTH_RESET_PASSWORD_REQUEST,
  AUTH_RESET_PASSWORD_SUCCESS
} from '~/redux/actions/types'
import auth from '~/services/auth'
import { identifySuccess } from './identify'
import { loadUser, verifyUser } from '~/redux/actions/user'
import { trackUserPasswordResetCompleted } from '~/redux/actions/analytics'

export const resetPasswordRequest = payload => {
  return {
    type: AUTH_RESET_PASSWORD_REQUEST,
    payload
  }
}

export const resetPasswordSuccess = payload => {
  return {
    type: AUTH_RESET_PASSWORD_SUCCESS,
    payload
  }
}

export const resetPasswordError = (error, meta = {}) => {
  return {
    type: AUTH_RESET_PASSWORD_ERROR,
    error: true,
    meta,
    payload: {
      error
    }
  }
}

export const resetPassword =
  ({ id, password, token }) =>
  async dispatch => {
    try {
      dispatch(resetPasswordRequest())
      const { accessToken } = await auth.resetPassword({
        customerId: id,
        password,
        resetToken: token
      })
      dispatch(identifySuccess(accessToken))
      dispatch(resetPasswordSuccess({ token: accessToken }))
      await dispatch(loadUser())
      await dispatch(verifyUser())
      dispatch(trackUserPasswordResetCompleted())
    } catch (error) {
      dispatch(resetPasswordError(error))
      throw error
    }
  }
