import { INITIALIZE_SESSION_ID_FROM_STORAGE } from '~/redux/actions/types'
import initialState from '~/redux/store/initial-state'

const session = (state = initialState.session, action) => {
  switch (action.type) {
    case INITIALIZE_SESSION_ID_FROM_STORAGE: {
      const { id } = action.payload.session

      return {
        ...state,
        id: id
      }
    }

    default:
      return state
  }
}

export default session
