import { OPEN_MODAL, CLOSE_MODAL } from '~/redux/actions/types'
import initialState from '~/redux/store/initial-state'

const modal = (state = initialState.modal, action) => {
  switch (action.type) {
    case OPEN_MODAL: {
      if (typeof action.payload.callback !== 'function') {
        throw new Error('Modal callback must be a function')
      }

      return {
        ...state,
        isOpen: true,
        message: action.payload.message,
        callback: action.payload.callback
      }
    }

    case CLOSE_MODAL: {
      return {
        ...state,
        isOpen: false
      }
    }

    default:
      return state
  }
}

export default modal
