import { applyMiddleware, createStore, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import thunk from 'redux-thunk'
import * as defaultReducer from '~/redux/reducers'
import defaultInitialState from '~/redux/store/initial-state'
import {
  persist as persistMiddleware,
  session as sessionMiddleware
} from '~/redux/middleware'
import api from '~/services/api'
import { initializeState } from '~/redux/actions/initialize'
import { createWrapper } from 'next-redux-wrapper'

const isBrowser = typeof window !== 'undefined'

const enhancers = composeWithDevTools({})

const makeStore = (reducer = {}, preloadedState = {}, middleware = []) => {
  const storeReducer = combineReducers({ ...defaultReducer, ...reducer })

  const storePreloadedState = { ...defaultInitialState, ...preloadedState }

  const storeEnhancer = enhancers(
    applyMiddleware(
      thunk.withExtraArgument({ api }),
      persistMiddleware,
      sessionMiddleware,
      ...middleware
    )
  )

  const store = createStore(storeReducer, storePreloadedState, storeEnhancer)

  if (isBrowser) store.dispatch(initializeState())

  return store
}

export const wrapper = createWrapper(makeStore)
