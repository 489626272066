import Router from 'next/router'
/**
 * Track client-side page views with Segment
 *
 * Note: this page call won't be fired if:
 * - <ConsentManagerWrapper /> is configured to wait for user consent prior tracking.
 * - Users have requested not to be tracked using <ConsentManagerWrapper /> preferences.
 *
 * See `pages/_app.js` and `components/Segment/ConsentManager/index.jsx`
 * for more details.
 */
Router.events.on('routeChangeComplete', url => {
  window.analytics.page(url)
})

const PageTracker = ({ children }) => children

export default PageTracker
