import {
  REMOVE_GIFT_CARD_REQUEST,
  REMOVE_GIFT_CARD_SUCCESS,
  REMOVE_GIFT_CARD_ERROR
} from '~/redux/actions/types'
import { getOrLoadCart } from './get-or-load-cart'

export const removeGiftCardRequest = appliedGiftCard => {
  return {
    type: REMOVE_GIFT_CARD_REQUEST,
    appliedGiftCardId: appliedGiftCard
  }
}

export const removeGiftCardSuccess = cart => {
  return {
    type: REMOVE_GIFT_CARD_SUCCESS,
    data: cart
  }
}

export const removeGiftCardError = (error, meta = {}) => {
  return {
    type: REMOVE_GIFT_CARD_ERROR,
    error: true,
    meta,
    payload: error
  }
}

export const removeGiftCard =
  appliedGiftCard =>
  async (dispatch, getState, { api }) => {
    try {
      dispatch(removeGiftCardRequest(appliedGiftCard))

      const { data: cart } = await getOrLoadCart(dispatch, getState)

      const updatedCart = await api.removeGiftCard(cart.id, appliedGiftCard)

      dispatch(removeGiftCardSuccess(updatedCart))
    } catch (error) {
      dispatch(
        removeGiftCardError(error, { appliedGiftCardId: appliedGiftCard })
      )
      throw error
    }
  }
