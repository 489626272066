import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { removeNotifications } from '~/redux/actions'
import { motion, AnimatePresence } from 'framer-motion'
import toasterStyles from './Toaster.module.scss'
import Notification from './notification'

const NotificationsToaster = () => {
  const notificationsArray = useSelector(state => state.notifications)
  const dispatch = useDispatch()
  const [showAlerts, setShowAlerts] = useState(false)
  const [isVisible, setIsVisible] = useState(true)

  return (
    <AnimatePresence>
      <section className={toasterStyles.container}>
        {isVisible && notificationsArray.length > 0 ? (
          <>
            <motion.div
              className={toasterStyles.toast}
              initial={{ zIndex: -1, opacity: 0, y: 50 }}
              animate={{ zIndex: 10, opacity: 1, y: 0 }}
              exit={{ zIndex: -1, opacity: 0, y: 50 }}>
              <div className={toasterStyles.decor}></div>
              <span className={toasterStyles.count}>
                You have {notificationsArray.length} unread notifications
              </span>
              <button
                className={toasterStyles.view}
                onClick={() => {
                  setShowAlerts(true)
                  setIsVisible(false)
                }}>
                View
              </button>
              <button
                className={toasterStyles.close}
                onClick={() => {
                  dispatch(removeNotifications())
                  setIsVisible(true)
                }}>
                [x]
              </button>
            </motion.div>
            {notificationsArray.map((notification, index) =>
              index >= notificationsArray.length - 6 && !showAlerts ? (
                <Notification
                  key={index}
                  index={index}
                  icon={notification.icon}
                  msg1={notification.msg1}
                  product={notification.product}
                  msg2={notification.msg2}
                  price={notification.price}
                  linkHref={notification.linkHref}
                  linkText={notification.linkText}
                  displayTime={10}
                />
              ) : null
            )}
          </>
        ) : (
          notificationsArray.map((notification, index) =>
            index >= notificationsArray.length - 6 && showAlerts ? (
              <Notification
                key={index}
                index={index}
                icon={notification.icon}
                msg1={notification.msg1}
                product={notification.product}
                msg2={notification.msg2}
                price={notification.price}
                linkHref={notification.linkHref}
                linkText={notification.linkText}
                displayTime={Infinity}
              />
            ) : null
          )
        )}
      </section>
    </AnimatePresence>
  )
}

export default NotificationsToaster
