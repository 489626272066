import {
  USER_AGREED_TO_TERMS,
  USER_AGREE_TO_TERMS_SUCCESS,
  USER_AGREE_TO_TERMS_ERROR
} from '../types'

export const userAgreedToTerms = () => ({
  type: USER_AGREED_TO_TERMS
})

export const userAgreeToTermsSuccess = payload => ({
  type: USER_AGREE_TO_TERMS_SUCCESS,
  payload
})

export const userAgreeToTermsError = error => ({
  type: USER_AGREE_TO_TERMS_ERROR,
  payload: error,
  error: true
})

export const userAgreeToTerms =
  () =>
  async (dispatch, getState, { api }) => {
    try {
      // Grab auth token
      const { auth } = getState()
      const { token } = auth
      const user = await api.getUser(auth.token)
      console.log('dbg checkUserTermsAgreement: ', user.id)
      // Query ShopifyAdmin to add tag
      await fetch(
        `/api/customer/tags/${Buffer.from(user.id).toString('base64')}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'customer-access-token': token
          },
          body: JSON.stringify({
            tags: 'agreedToTerms:true'
          })
        }
      )
      // Dispatch action with status
      dispatch(userAgreeToTermsSuccess(true))
    } catch (error) {
      dispatch(userAgreeToTermsError(error))
      throw error
    }
  }
