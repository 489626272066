import {
  LOAD_CART_REQUEST,
  LOAD_CART_SUCCESS,
  LOAD_CART_ERROR
} from '~/redux/actions/types'

import { forgetCart } from './forget-cart'

export const loadCartRequest = () => {
  return {
    type: LOAD_CART_REQUEST
  }
}

export const loadCartSuccess = cart => {
  return {
    type: LOAD_CART_SUCCESS,
    data: cart
  }
}

export const loadCartError = error => {
  return {
    type: LOAD_CART_ERROR,
    error: true,
    payload: error
  }
}

export const loadCart =
  () =>
  async (dispatch, getState, { api }) => {
    const createOrLoadCart = async cartId => {
      // if there is no cart token or number stored in Redux/localStorage, create a new order
      if (!cartId) {
        return await api.createOrder()
      }

      try {
        const cart = await api.getOrder(cartId)

        // if the cart token or number stored in Redux/localStorage has been completed,
        // clear and create a new order
        if (cart.completedAt) {
          await dispatch(forgetCart())
          return await api.createOrder()
        }
        return cart
      } catch (error) {
        // if the cart token or number stored in Redux/localStorage is invalid, clear and create a new order
        await dispatch(forgetCart())
        return await api.createOrder()
      }
    }

    const { cart } = getState()
    try {
      dispatch(loadCartRequest())
      const newCart = await createOrLoadCart(cart.data.id)
      dispatch(loadCartSuccess(newCart))
    } catch (error) {
      dispatch(loadCartError(error))
      throw error
    }
  }
