const SUBDOMAIN = process.env.SHOPIFY_SUBDOMAIN
const STOREFRONT_ACCESS_TOKEN = process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN

export const shopifyClient = async ({ query, variables }) => {
  if (query) {
    const data = await fetch(
      `https://${SUBDOMAIN}.myshopify.com/api/2022-10/graphql.json`,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query, variables })
      }
    )

    return await data.json()
  } else return {}
}
