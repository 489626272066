import {
  INITIALIZE_STATE_FROM_STORAGE,
  INITIALIZE_BOOKMARKS_FROM_STORAGE,
  INITIALIZE_SESSION_ID_FROM_STORAGE,
  INITIALIZE_NEWSLETTER_FROM_STORAGE
} from '~/redux/actions/types'
import storage from '~/services/storage'

import { getCookie } from 'cookies-next'
import { loadCart } from '~/redux/actions/cart'
import { getToken } from '~/redux/actions/auth'
import { loadUser, verifyUser } from '~/redux/actions/user'
import { v4 as uuidv4 } from 'uuid'

export const initializeNewsletterFromStorage = () => {
  const payload = {
    newsletter: {
      hideModal: getCookie('newsletter-hide-modal') ? true : false
    }
  }

  return {
    type: INITIALIZE_NEWSLETTER_FROM_STORAGE,
    payload
  }
}

export const initializeStateFromStorage = () => {
  const payload = {
    cart: {
      data: {
        id: storage.getItem('cart-id')
      }
    }
  }

  return {
    type: INITIALIZE_STATE_FROM_STORAGE,
    payload
  }
}

export const initializeSessionIDFromStorage = () => {
  const payload = {
    session: {
      id: storage.getItem('session-id')
        ? storage.getItem('session-id')
        : uuidv4()
    }
  }

  return {
    type: INITIALIZE_SESSION_ID_FROM_STORAGE,
    payload
  }
}

export const initializeBookmarksFromStorage = () => {
  const payload = {
    bookmarks: JSON.parse(storage.getItem('bookmarks')) || {}
  }

  return {
    type: INITIALIZE_BOOKMARKS_FROM_STORAGE,
    payload
  }
}

export const initializeState = () => async dispatch => {
  try {
    await dispatch(initializeStateFromStorage())
    await dispatch(initializeSessionIDFromStorage())
    await dispatch(initializeNewsletterFromStorage())
    await dispatch(initializeBookmarksFromStorage())
  } catch (error) {
    console.log(error)
  }

  try {
    await dispatch(loadCart())
  } catch (error) {
    console.log(error)
  }

  try {
    await dispatch(getToken())
    await dispatch(loadUser())
    await dispatch(verifyUser())
  } catch (error) {
    if (error.message !== 'User is not logged in.') {
      console.log(error)
    } else {
      console.log('User is not logged in.')
    }
  }
}
