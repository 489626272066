import {
  CLIENT_SALE_ERROR,
  CLIENT_SALE_REQUEST,
  CLIENT_SALE_SUCCESS
} from '~/redux/actions/types'

export const clientSaleRequest = ({ saleId, userId }) => {
  return {
    type: CLIENT_SALE_REQUEST,
    saleId: saleId,
    userId: userId
  }
}

export const clientSaleSuccess = bid => {
  return {
    type: CLIENT_SALE_SUCCESS,
    data: bid
  }
}

export const clientSaleError = (error, meta = {}) => {
  return {
    type: CLIENT_SALE_ERROR,
    error: true,
    meta,
    payload: error
  }
}

export const getClientSale = ({ saleId, userId }) => {
  const encodedUserId = btoa(userId)
  return async dispatch => {
    try {
      dispatch(clientSaleRequest({ saleId, encodedUserId }))
      const sale = await fetch(`/api/basta/clientAuction/${saleId}`, {
        method: 'POST',
        body: JSON.stringify({ saleId, userId: encodedUserId })
      })
      const saleResult = await sale.json()
      dispatch(clientSaleSuccess(saleResult?.data?.sale))
    } catch (e) {
      dispatch(clientSaleError(e, { saleId, encodedUserId }))
      throw e
    }
  }
}
