const DynamicInput = ({
  name,
  register,
  rules,
  errors = {},
  showErrors = false,
  ...props
}) => {
  return (
    <div className={`input-wrapper${errors[name] ? ' input-error' : ''}`}>
      <input
        name={name}
        aria-invalid={errors[name] ? true : false}
        {...(register &&
          register(name, {
            ...rules,
            onBlur: e => {
              const value = e.target.value
              e.target.parentNode.classList.toggle('input-active', value)
            },
            onChange: e => {
              const value = e.target.value
              e.target.parentNode.classList.toggle('input-active', value)
            }
          }))}
        onFocus={e => {
          e.target.parentNode.classList.add('input-active')
        }}
        {...props}
      />
      {showErrors && errors[name] && (
        <p role="alert" className="error-message">
          {errors[name].message}
        </p>
      )}
    </div>
  )
}
export default DynamicInput
