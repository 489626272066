import {
  AUTH_REGISTER_ERROR,
  AUTH_REGISTER_REQUEST,
  AUTH_REGISTER_SUCCESS
} from '~/redux/actions/types'
import auth from '~/services/auth'
import { associateCartWithUser } from '~/redux/actions/cart'
import {
  createUserStripeAccount,
  loadUser,
  modifyUserName,
  modifyUserPhone
} from '~/redux/actions/user'
import { identifySuccess } from './identify'
import { trackUserSignedUp } from '~/redux/actions/analytics'

export const registerRequest = email => {
  return {
    type: AUTH_REGISTER_REQUEST,
    payload: {
      email
    }
  }
}

export const registerSuccess = payload => {
  return {
    type: AUTH_REGISTER_SUCCESS,
    payload
  }
}

export const registerError = (error, meta = {}) => {
  return {
    type: AUTH_REGISTER_ERROR,
    error: true,
    meta,
    payload: {
      error
    }
  }
}

export const register =
  ({
    email,
    password,
    phone,
    smsConsent,
    emailMarketingConsent,
    firstName,
    lastName,
    whatsAppNumber,
    prefersWhatsApp,
    locale
  }) =>
  async (dispatch, getState, { api }) => {
    const { cart } = getState()

    try {
      dispatch(registerRequest(email))
      const { accessToken } = await auth.register({ email, password })
      dispatch(identifySuccess(accessToken))

      const isOrderInProgress =
        cart.data.lineItems && cart.data.lineItems.length
      if (isOrderInProgress) {
        try {
          await dispatch(associateCartWithUser())
        } catch (error) {
          console.log(error)
        }
      }

      // If Email consent is given, hit Klaviyo API and subscribe to the list
      if (email && emailMarketingConsent) {        
        await fetch('/api/klaviyo/newsletter', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            email,
            emailMarketingConsent
          })
        })
          .then(res => res.json())
          .then(data => {
            console.log('[Klaviyo API: Email]', data)
          })
          .catch(err => {
            throw new Error(err)
          })
      }

      // If Phone consent is given and phone number is not empty
      // Hit Klaviyo API and subscribe to the list
      if (
        (phone !== '+' && smsConsent) ||
        (whatsAppNumber !== '+' && prefersWhatsApp)
      ) {
        await fetch('/api/klaviyo/newsletter', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            email,
            phone: smsConsent ? phone : '+',
            smsConsent,
            whatsAppNumber,
            prefersWhatsApp,
            locale
          })
        })
          .then(res => res.json())
          .then(data => {
            console.log('[Klaviyo API: SMS]', data)
          })
          .catch(err => {
            throw new Error(err)
          })
      }

      dispatch(registerSuccess({ email, token: accessToken }))

      // Load user data
      await dispatch(loadUser())
      // Create Stripe account + add tag to Shopify admin
      await dispatch(createUserStripeAccount())
      await dispatch(modifyUserName(firstName, lastName))
      await dispatch(modifyUserPhone(phone))

      // Trigger analytic tracking for user registration
      dispatch(trackUserSignedUp())

      const user = await api.getUser(accessToken)
      if (user && user.id) {
        // Add the verified:true tag to Shopify admin
        await fetch(
          `/api/customer/tags/${Buffer.from(user.id).toString('base64')}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'customer-access-token': accessToken
            },
            body: JSON.stringify({ tags: 'verified:true' })
          }
        )
      } else {
        throw new Error('User data is not available.')
      }
    } catch (error) {
      dispatch(registerError(error, { email }))
      throw error
    }
  }
