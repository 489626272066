import OptimizedImage from './OptimizedImage'

/**
 * @image a mapped Contentful or Shopify Asset, see ./cms/utils/mappers/image.js
 * @quality a value that can be used to override the default image quality of 75
 * @className a custom class passed to the <picture></picture> wrapper
 * @containerStyle an object of css inline styles to the image wrapper element
 * @maxWidth an integer value to cap image max-width using NextImage `sizes` prop
 * @props pulls all the NextImage properties directly.
 */
const ResponsiveImage = ({
  image,
  quality = 75,
  className,
  containerStyle = {},
  maxWidth = null,
  onClick,
  ...props
}) => {
  if (!image) return
  const { description = '' } = image

  return description === '' ? (
    <picture
      className={className}
      style={{ minWidth: '100%', ...containerStyle }}
      onClick={onClick}>
      <OptimizedImage
        image={image}
        maxWidth={maxWidth}
        quality={quality}
        props={props}
      />
    </picture>
  ) : (
    <div
      onClick={onClick}
      className="imageBlock"
      style={{ minWidth: '100%', ...containerStyle }}>
      <picture className={className}>
        <OptimizedImage
          image={image}
          maxWidth={maxWidth}
          quality={quality}
          props={props}
        />
      </picture>
      <p className="font--body-4">{description}</p>
    </div>
  )
}

export default ResponsiveImage
