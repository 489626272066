import {
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS
} from '~/redux/actions/types'
import auth from '~/services/auth'
import { associateCartWithUser } from '~/redux/actions/cart'
import { loadUser, verifyUser } from '~/redux/actions/user'
import { trackUserSignedIn } from '~/redux/actions/analytics'
import { identifySuccess } from './identify'

export const loginRequest = email => {
  return {
    type: AUTH_LOGIN_REQUEST,
    payload: {
      email
    }
  }
}

export const loginSuccess = payload => {
  return {
    type: AUTH_LOGIN_SUCCESS,
    payload
  }
}

export const loginError = (error, meta = {}) => {
  return {
    type: AUTH_LOGIN_ERROR,
    error: true,
    meta,
    payload: {
      error
    }
  }
}

export const login =
  ({ email, password }) =>
  async (dispatch, getState) => {
    const { cart } = getState()

    try {
      dispatch(loginRequest(email))
      const { accessToken } = await auth.login({ email, password })
      dispatch(identifySuccess(accessToken))

      const isOrderInProgress =
        cart.data.lineItems && cart.data.lineItems.length
      if (isOrderInProgress) {
        try {
          await dispatch(associateCartWithUser())
        } catch (error) {
          console.log(error)
        }
      }

      dispatch(loginSuccess({ email, token: accessToken }))
      await dispatch(loadUser())
      await dispatch(verifyUser({ isLogin: true }))
      dispatch(trackUserSignedIn())
    } catch (error) {
      dispatch(loginError(error, { email }))
      throw error
    }
  }
