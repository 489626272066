import {
  SET_NEWSLETTER_HIDE_MODAL,
  INITIALIZE_NEWSLETTER_FROM_STORAGE
} from '~/redux/actions/types'
import initialState from '~/redux/store/initial-state'

const newsletter = (state = initialState.newsletter, action) => {
  switch (action.type) {
    case INITIALIZE_NEWSLETTER_FROM_STORAGE: {
      const { hideModal } = action.payload.newsletter

      return {
        ...state,
        hideModal: hideModal
      }
    }

    case SET_NEWSLETTER_HIDE_MODAL: {
      return {
        ...state,
        hideModal: true
      }
    }

    default:
      return state
  }
}

export default newsletter
