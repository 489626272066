import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  REMOVE_NOTIFICATIONS
} from '~/redux/actions/types'
import initialState from '~/redux/store/initial-state'
import { MerkleJson } from 'merkle-json'
import storage from '~/services/storage'

const notifcations = (state = initialState.notifications, action) => {
  const mj = new MerkleJson()
  const seenNotifications = storage.getItem('seenNotifications') || []
  switch (action.type) {
    case ADD_NOTIFICATION:
      if (seenNotifications.length > 0) {
        const newNotificationHash = mj.hash(action.payload)
        if (seenNotifications.includes(newNotificationHash)) {
          return [...state]
        } else {
          return [...state, action.payload]
        }
      } else {
        return [...state, action.payload]
      }
    case REMOVE_NOTIFICATION:
      if (state[action.payload]) {
        const notificationHash = mj.hash(state[action.payload])
        storage.setItem('seenNotifications', [
          notificationHash,
          ...seenNotifications
        ])
      }
      return [
        ...state.slice(0, action.payload),
        ...state.slice(action.payload + 1)
      ]

    case REMOVE_NOTIFICATIONS:
      if (state.length > 0) {
        storage.setItem(
          'seenNotifications',
          seenNotifications.concat(
            ...state.map(notification => mj.hash(notification))
          )
        )
      }
      return []

    default:
      return state
  }
}

export default notifcations
