const toUsdCurrency = (number, type) => {
  if (!isNaN(parseInt(number))) {
    if (type === 'integer') {
      const currencyNum = number / 100
      if (currencyNum % 1 === 0) return fraction.format(currencyNum)
      else return formatter.format(currencyNum)
    } else if (type === 'float') {
      if (number % 1 === 0) return fraction.format(number)
      else return formatter.format(number)
    } else {
      const currencyNum = number / 100
      if (currencyNum % 1 === 0) return fraction.format(currencyNum)
      else return formatter.format(currencyNum)
    }
  } else {
    return null
  }
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
})

const fraction = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})

const toTime = dateString => {
  const date = new Date(dateString)
  const [, mon, dd, yyyy] = date.toDateString().split(' ') // Return formart [Tue, Oct, 99, 9999]

  return `${dd}-${mon}-${yyyy} ${
    date.getHours() >= 10 ? date.getHours() : `0${date.getHours()}`
  }:${date.getMinutes() >= 10 ? date.getMinutes() : `0${date.getMinutes()}`}:${
    date.getSeconds() >= 10 ? date.getSeconds() : `0${date.getSeconds()}`
  }`
}

const toDate = dateString => {
  const date = new Date(dateString)
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
}

const toSlug = text => {
  return text
    .toLowerCase()
    .trim() // remove spaces from both ends
    .normalize('NFD') // separate accent from letter
    .replace(/[\u0300-\u036f]/g, '') // remove accent
    .replace(/\s+/g, '-') // replace spaces with -
    .replace(/[^\w-]+/g, '') // remove all non-word chars
    .replace(/--+/g, '-') // replace multiple - with single -
}

export { toUsdCurrency, toTime, toDate, toSlug }
