import {
  AUTH_IDENTIFY_REQUEST,
  AUTH_LOGIN_REQUEST,
  AUTH_REGISTER_REQUEST,
  AUTH_RESET_PASSWORD_REQUEST,
  AUTH_IDENTIFY_ERROR,
  AUTH_LOGIN_ERROR,
  AUTH_REGISTER_ERROR,
  AUTH_RESET_PASSWORD_ERROR,
  AUTH_LOGOUT_SUCCESS,
  AUTH_IDENTIFY_SUCCESS,
  CLEAR_SESSION,
  AuthStatus
} from '~/redux/actions/types'
import initialState from '~/redux/store/initial-state'

const auth = (state = initialState.auth, action) => {
  switch (action.type) {
    case AUTH_IDENTIFY_REQUEST:
    case AUTH_LOGIN_REQUEST:
    case AUTH_REGISTER_REQUEST:
    case AUTH_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        status: AuthStatus.Loading
      }

    case AUTH_IDENTIFY_ERROR:
    case AUTH_LOGIN_ERROR:
    case AUTH_REGISTER_ERROR:
    case AUTH_RESET_PASSWORD_ERROR:
      return {
        ...state,
        status: AuthStatus.Failed,
        token: null
      }

    case AUTH_LOGOUT_SUCCESS:
    case CLEAR_SESSION:
      return {
        ...state,
        status: AuthStatus.Idle,
        token: null
      }

    case AUTH_IDENTIFY_SUCCESS:
      return {
        ...state,
        status: AuthStatus.Succeeded,
        token: action.payload.token
      }

    default:
      return state
  }
}

export default auth
