import { loadCart } from './load-cart'

// TODO: if cart is not loaded but is currently loading, wait for the cart to load instead of creating another new cart
export const getOrLoadCart = async (dispatch, getState) => {
  let cart = getState().cart
  if (!cart.data.id) {
    try {
      await dispatch(loadCart())
    } catch (error) {
      console.log(error)
    }

    cart = getState().cart
  }

  return cart
}
