import { useState } from 'react'
import Modal from 'react-modal'
import { useRouter } from 'next/router'

import styles from './index.module.scss'
import NewsletterSection from '../Section'
import { useNewsletterModal, useUser } from '~/hooks/actions'

Modal.defaultStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backdropFilter: 'blur(5px)',
    zIndex: 1001,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch'
  }
}

const NewsletterModal = () => {
  const router = useRouter()
  const { hideModal, setNewsletterHideModal } = useNewsletterModal()
  const [open, setOpen] = useState(true)
  const { hasNewsletterSubscription } = useUser()

  const handleClose = () => {
    setNewsletterHideModal()
    setOpen(false)
  }

  const isHomePage = router.pathname === '/'
  const isAuctionListingPage = router.pathname.startsWith('/auctions/')

  return hideModal ||
    hasNewsletterSubscription ||
    isHomePage ||
    isAuctionListingPage ? null : (
    <Modal isOpen={open} onRequestClose={handleClose}>
      <div className={styles.wrapper}>
        <NewsletterSection
          close
          closeCallback={handleClose}
          className={styles.section}
        />
      </div>
    </Modal>
  )
}

export default NewsletterModal
