import { default as NextImage } from 'next/image'
import generateSizes from '~/utils/generate-sizes'

// Check source : Shopify or Contentful
function isShopifyCdn(url) {
  return url.startsWith('https://cdn.shopify.com')
}

// Custom Image component
const OptimizedImage = ({ image, maxWidth, quality, ...props }) => {
  const { url, title, width, height } = image
  // Static Placeholder asset
  const blurPixelSrc =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN8+h8AAs8B5qzsEekAAAAASUVORK5CYII='
  // Default Sizes prop
  // Can be overridden by directly supplying `sizes` to the component via ...props
  const sizes = maxWidth ? generateSizes(maxWidth) : generateSizes()

  const imageLoader = ({ src, width, quality }) => {
    return isShopifyCdn(url) // Check if its Shopify CDN'ed image
      ? `${src}&width=${width}` // Pass shopify cdn prop
      : `${src}?w=${width}&q=${quality}` // Pass contentful cdn image props width & quality
  }

  return (
    <NextImage
      src={url}
      alt={title}
      placeholder="blur"
      blurDataURL={blurPixelSrc}
      layout="responsive"
      quality={quality}
      width={width}
      height={height}
      sizes={sizes}
      loader={imageLoader}
      {...props}
    />
  )
}
export default OptimizedImage
