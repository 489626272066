import storage from '~/services/storage'

import { setCookie } from 'cookies-next'

export const persist = store => next => action => {
  const previousState = store.getState()
  const result = next(action)
  const nextState = store.getState()

  if (previousState.cart.data.id !== nextState.cart.data.id) {
    storage.setItem('cart-id', nextState.cart.data.id)
  }

  if (previousState.session.id !== nextState.session.id) {
    storage.setItem('session-id', nextState.session.id)
  }

  if (previousState.newsletter.hideModal !== nextState.newsletter.hideModal) {
    setCookie('newsletter-hide-modal', nextState.newsletter.hideModal, {
      sameSite: 'Strict',
      secure: true
    })
  }

  if (previousState.bookmarks !== nextState.bookmarks) {
    storage.setItem('bookmarks', JSON.stringify(nextState.bookmarks))
  }

  return result
}
