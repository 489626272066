import { OPEN_MODAL, CLOSE_MODAL } from '../types'

export const openModal = (message, callback) => {
  return {
    type: OPEN_MODAL,
    payload: {
      message: message,
      callback: callback
    }
  }
}

export const closeModal = () => {
  return {
    type: CLOSE_MODAL
  }
}
