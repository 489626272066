const hasLocalStorage = () => {
  try {
    return (
      typeof window !== 'undefined' &&
      window.localStorage !== 'undefined' &&
      window.localStorage !== null
    )
  } catch (error) {
    return false
  }
}

class Storage {
  setItem(key, value) {
    if (!hasLocalStorage()) return
    if (value) {
      window.localStorage.setItem(key, value)
    } else {
      window.localStorage.removeItem(key)
    }
  }

  removeItem(key) {
    if (!hasLocalStorage()) return
    window.localStorage.removeItem(key)
  }

  getItem(key) {
    if (!hasLocalStorage()) return null
    return window.localStorage.getItem(key)
  }

  clear() {
    if (!hasLocalStorage()) return
    window.localStorage.clear()
  }
}

const storage = new Storage()

export default storage
