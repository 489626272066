import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useBasta } from '~/hooks/actions/use-basta'
import { getProducts } from '../../../graphql-cms/products'
import { createClient } from 'graphql-ws'
import { setClientInstance } from '~/redux/actions/basta'
import { useUser } from '~/hooks/actions'
import { addNotification } from '~/redux/actions'

const useBastaWebSocket = () => {
  const { addActivity, updateItem, closeSale } = useBasta()
  const dispatch = useDispatch()
  const { user } = useUser()
  const { salesWon, clientInstance, salesProductDataExpiresAt } = useSelector(
    state => state.basta
  )
  const bookmarks = useSelector(state => state.bookmarks)
  const bookmarksArray = Object.keys(bookmarks)

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      if (user.id && !clientInstance) {
        const products = await getProducts({})
        const createClientRequest = createClient({
          url: 'wss://client.api.basta.ai/graphql',
          connectionParams: async () => {
            const response = await fetch(`/api/basta/token`, {
              method: 'POST',
              body: JSON.stringify({ userId: user.id })
            })
            const json = await response.json()
            return {
              token: `${json.data.createBidderToken.token}`
            }
          }
        })
        dispatch(setClientInstance(user.id))
        subscribeToItemsChange(products, createClientRequest)
        subscribeToSalesChange(products, createClientRequest)
        return () => {
          createClientRequest.stop()
        }
      }
    }

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id])
  const subscribeToItemsChange = (products, client) => {
    for (const product of products) {
      if (product.bastaSaleId && product.bastaItemId) {
        if (client) {
          client.subscribe(
            {
              query: `subscription {
            itemChanged(saleId: "${
              product.bastaSaleId
            }", itemIds: ${JSON.stringify([product.bastaItemId])}) {
                ... on Item {
                    id
                    bidStatus
                    saleId
                    reserveMet
                    description
                    title
                    currentBid
                    itemDates {
                        closingEnd
                        closingStart
                    }
                    status
                    totalBids
                    nextAsks(iterations: 300)
                    bids {
                        amount
                        saleId
                        itemId
                        date
                        bidStatus
                    }
                    userBids {
                        amount
                        saleId
                        itemId
                        maxAmount
                        date
                        bidStatus
                    }
                }
            }
        }`
            },
            {
              next: data => {
                updateItem(data.data.itemChanged)
                if (
                  data.data.itemChanged.bidStatus !== 'LOSING' &&
                  data.data.itemChanged.status !== 'ITEM_CLOSED' &&
                  data.data.itemChanged.currentBid
                ) {
                  bookmarksArray.forEach(bookmark => {
                    if (bookmark === product.slug) {
                      dispatch(
                        addNotification({
                          icon: 'bookmark',
                          msg: 'Your saved item: ',
                          product: data.data.itemChanged.title,
                          msg2: 'is now ',
                          price: data.data.itemChanged.currentBid,
                          linkHref: '/products/' + product.slug,
                          linkText: 'Place Bid'
                        })
                      )
                    }
                  })
                }

                if (
                  data?.data?.itemChanged.status === 'ITEM_OPEN' &&
                  data?.data?.itemChanged.bidStatus === 'LOSING'
                ) {
                  dispatch(
                    addNotification({
                      icon: '⛔',
                      msg1: 'You have been outbid: ',
                      product: data.data.itemChanged.title,
                      msg2: 'is now ',
                      price: data?.data?.itemChanged.currentBid,
                      linkHref: '/products/' + product.slug,
                      linkText: 'Place Bid'
                    })
                  )
                }
                if (data.data.itemChanged.bidStatus !== 'NOT_BIDDING') {
                  addActivity(data)
                }
              },
              error: error => {
                console.error('Basta websocket', error)
              },
              complete: () => {}
            }
          )
        }
      }
    }
  }
  const subscribeToSalesChange = (products, client) => {
    for (const product of products) {
      if (product.bastaSaleId) {
        if (client) {
          client.subscribe(
            {
              query: `subscription {
            saleChanged(saleId: "${product.bastaSaleId}") {
                ... on Sale {
                    id
                    description
                    title
                    status
                    items {
                        edges {
                            node {
                                itemDates {
                                  closingEnd
                                  closingStart
                                }
                                bidStatus
                                currentBid
                                totalBids
                                id
                            }
                        }
                    }
                }
            }
        }`
            },
            {
              next: data => {
                const closingDate = new Date(
                  data?.data?.saleChanged?.items.edges[0].node.itemDates
                    .closingEnd ||
                    data?.data?.saleChanged?.items.edges[0].node.itemDates
                      .closingStart
                )
                closingDate.setDate(closingDate.getDate() + 2)
                if (
                  data?.data?.saleChanged.status === 'CLOSED' &&
                  data?.data?.saleChanged?.items.edges[0].node.bidStatus ===
                    'WON' &&
                  closingDate.getTime() > new Date().getTime()
                ) {
                  dispatch(
                    addNotification({
                      icon: '🏆',
                      msg1: 'You Won: ',
                      product: data.data.saleChanged.title,
                      linkHref: '/products/' + product.slug,
                      linkText: 'View Details'
                    })
                  )
                  data.data.saleClosed = true
                  closeSale({
                    saleClosedEvent: data.data.saleChanged,
                    userId: user.id,
                    salesWon,
                    salesProductDataExpiresAt
                  })
                }
                if (data?.data?.saleChanged.status === 'CLOSING') {
                  dispatch(
                    addNotification({
                      icon: '⌛',
                      msg1: '',
                      product: data.data.saleChanged.title,
                      msg2: 'is ending soon',
                      linkHref: '/products/' + product.slug,
                      linkText: 'View Details'
                    })
                  )
                }
              },
              error: error => {
                console.error(error)
              },
              complete: () => {}
            }
          )
        }
      }
    }
  }
}

export default useBastaWebSocket
