import { useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '~/redux/actions/overlay'

export function useOverlay() {
  const dispatch = useDispatch()
  const verificationOverlay = useSelector(state => state.overlay.verification)
  const contentOverlay = useSelector(state => state.overlay.content)
  const alertOverlay = useSelector(state => state.overlay.alert)
  const verificationOverlayStep = useSelector(
    state => state.overlay.verificationStep
  )

  const toggleContentOverlay = useMemo(
    () => bindActionCreators(actions.toggleContent, dispatch),
    [dispatch]
  )

  const toggleVerificationOverlay = useMemo(
    () => bindActionCreators(actions.toggleVerification, dispatch),
    [dispatch]
  )

  const toggleAlertOverlay = useMemo(
    () => bindActionCreators(actions.toggleAlert, dispatch),
    [dispatch]
  )

  return {
    alertOverlay,
    contentOverlay,
    verificationOverlay,
    verificationOverlayStep,
    toggleAlertOverlay,
    toggleContentOverlay,
    toggleVerificationOverlay
  }
}
