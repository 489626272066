import {
  MODIFY_CART_QUANTITY_ERROR,
  MODIFY_CART_QUANTITY_REQUEST,
  MODIFY_CART_QUANTITY_SUCCESS
} from '~/redux/actions/types'
import { getOrLoadCart } from './get-or-load-cart'

export const modifyQuantityRequest = lineItemId => {
  return {
    type: MODIFY_CART_QUANTITY_REQUEST,
    lineItem: lineItemId
  }
}

export const modifyQuantitySuccess = (cart, variant, quantity) => {
  return {
    type: MODIFY_CART_QUANTITY_SUCCESS,
    data: cart,
    variant,
    quantity
  }
}

export const modifyQuantityError = (error, meta = {}) => {
  return {
    type: MODIFY_CART_QUANTITY_ERROR,
    error: true,
    meta,
    payload: error
  }
}

export const modifyQuantity =
  (lineItemId, quantity) =>
  async (dispatch, getState, { api }) => {
    try {
      dispatch(modifyQuantityRequest(lineItemId))

      const { data: cart } = await getOrLoadCart(dispatch, getState)
      const { user } = getState()

      const updatedCart = await api.updateOrderItem(cart.id, {
        lineItemId,
        quantity,
        user: user.data
      })

      const modifiedLineItem = cart.lineItems.find(li => li.id === lineItemId)
      const variant = modifiedLineItem.variant
      const updatedQuantity = quantity - modifiedLineItem.quantity

      dispatch(modifyQuantitySuccess(updatedCart, variant, updatedQuantity))
    } catch (error) {
      dispatch(modifyQuantityError(error, { lineItemId, quantity }))
    }
  }
