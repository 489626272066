import { useDispatch } from 'react-redux'
import * as actions from '../../redux/actions/analytics'
import { useMemo } from 'react'
import { bindActionCreators } from 'redux'

export function useAnalytics() {
  const dispatch = useDispatch()

  const identify = useMemo(
    () => bindActionCreators(actions.identify, dispatch),
    [dispatch]
  )

  const trackCartViewed = useMemo(
    () => bindActionCreators(actions.trackCartViewed, dispatch),
    [dispatch]
  )

  const trackCollectionClicked = useMemo(
    () => bindActionCreators(actions.trackCollectionClicked, dispatch),
    [dispatch]
  )

  const trackEmailCaptured = useMemo(
    () => bindActionCreators(actions.trackEmailCaptured, dispatch),
    [dispatch]
  )

  const trackProductClicked = useMemo(
    () => bindActionCreators(actions.trackProductClicked, dispatch),
    [dispatch]
  )

  const trackProductListFiltered = useMemo(
    () => bindActionCreators(actions.trackProductListFiltered, dispatch),
    [dispatch]
  )

  const trackProductListViewed = useMemo(
    () => bindActionCreators(actions.trackProductListViewed, dispatch),
    [dispatch]
  )

  const trackProductViewed = useMemo(
    () => bindActionCreators(actions.trackProductViewed, dispatch),
    [dispatch]
  )

  const trackPromotionClicked = useMemo(
    () => bindActionCreators(actions.trackPromotionClicked, dispatch),
    [dispatch]
  )

  const trackPromotionViewed = useMemo(
    () => bindActionCreators(actions.trackPromotionViewed, dispatch),
    [dispatch]
  )

  const trackStockRequestCreated = useMemo(
    () => bindActionCreators(actions.trackStockRequestCreated, dispatch),
    [dispatch]
  )

  const trackUserPasswordResetCompleted = useMemo(
    () => bindActionCreators(actions.trackUserPasswordResetCompleted, dispatch),
    [dispatch]
  )

  const trackUserPasswordResetStarted = useMemo(
    () => bindActionCreators(actions.trackUserPasswordResetStarted, dispatch),
    [dispatch]
  )

  const trackUserSignedIn = useMemo(
    () => bindActionCreators(actions.trackUserSignedIn, dispatch),
    [dispatch]
  )

  const trackUserSignedOut = useMemo(
    () => bindActionCreators(actions.trackUserSignedOut, dispatch),
    [dispatch]
  )

  const trackUserSignedUp = useMemo(
    () => bindActionCreators(actions.trackUserSignedUp, dispatch),
    [dispatch]
  )

  // 1R added events
  const trackBidClicked = useMemo(
    () => bindActionCreators(actions.trackBidClicked, dispatch),
    [dispatch]
  )

  const trackBidConfirmed = useMemo(
    () => bindActionCreators(actions.trackBidConfirmed, dispatch),
    [dispatch]
  )

  const trackBidPlaced = useMemo(
    () => bindActionCreators(actions.trackBidPlaced, dispatch),
    [dispatch]
  )

  const trackArticleViewed = useMemo(
    () => bindActionCreators(actions.trackArticleViewed, dispatch),
    [dispatch]
  )

  const trackSearchViewed = useMemo(
    () => bindActionCreators(actions.trackSearchViewed, dispatch),
    [dispatch]
  )

  const trackBookmarkAdded = useMemo(
    () => bindActionCreators(actions.trackBookmarkAdded, dispatch),
    [dispatch]
  )

  return {
    identify,
    trackArticleViewed,
    trackBidClicked,
    trackBidConfirmed,
    trackBidPlaced,
    trackBookmarkAdded,
    trackCartViewed,
    trackCollectionClicked,
    trackEmailCaptured,
    trackProductClicked,
    trackProductListFiltered,
    trackProductListViewed,
    trackProductViewed,
    trackPromotionClicked,
    trackPromotionViewed,
    trackSearchViewed,
    trackStockRequestCreated,
    trackUserPasswordResetCompleted,
    trackUserPasswordResetStarted,
    trackUserSignedIn,
    trackUserSignedOut,
    trackUserSignedUp
  }
}
