import {
  GET_AUCTIONS_ERROR,
  GET_AUCTIONS_REQUEST,
  GET_AUCTIONS_SUCCESS
} from '~/redux/actions/types'

export const getAuctionsRequest = id => {
  return {
    type: GET_AUCTIONS_REQUEST,
    id: id
  }
}

export const getAuctionsSuccess = auctions => {
  return {
    type: GET_AUCTIONS_SUCCESS,
    data: auctions
  }
}

export const getAuctionsError = (error, meta = {}) => {
  return {
    type: GET_AUCTIONS_ERROR,
    error: true,
    meta,
    payload: error
  }
}

export const getAuctions = filter => {
  return async dispatch => {
    try {
      dispatch(getAuctionsRequest(filter))
      const sales = await fetch(`/api/basta/auctions`, {
        method: 'POST',
        body: JSON.stringify({ filter })
      })
      const salesResponse = await sales.json()
      let auctions = {}
      salesResponse.data.sales.edges.map(edge => {
        auctions[edge.node.id] = edge.node
      })
      dispatch(getAuctionsSuccess(auctions))
    } catch (e) {
      dispatch(getAuctionsError(e, { filter }))
      throw e
    }
  }
}
