import {
  AmityStatus,
  LOAD_COMMENTS_REQUEST,
  LOAD_COMMENTS_ERROR,
  LOAD_COMMENTS_SUCCESS,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_ERROR,
  ADD_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_ERROR,
  DELETE_COMMENT_REQUEST,
  FLAG_COMMENT_SUCCESS,
  FLAG_COMMENT_ERROR,
  FLAG_COMMENT_REQUEST,
  AMITY_CHECK_PERMISSIONS_ERROR,
  AMITY_CHECK_PERMISSIONS_SUCCESS,
  AMITY_CHECK_PERMISSIONS_REQUEST,
  CLEAR_COMMENTS
} from '~/redux/actions/types'
import initialState from '~/redux/store/initial-state'

const amity = (state = initialState.amity, action) => {
  switch (action.type) {
    case LOAD_COMMENTS_REQUEST: {
      return {
        ...state,
        loadCommentsStatus: AmityStatus.Loading
      }
    }

    case LOAD_COMMENTS_SUCCESS: {
      let comments
      if (action.payload.parentId) {
        comments = state.comments.slice()
        comments.forEach(comment => {
          if (comment.commentId === action.payload.parentId) {
            comment.children = comment.children.concat(action.payload.comments)
          }
        })
      } else {
        const oldComments = state.comments.slice()
        comments = oldComments.concat(action.payload.comments)
      }
      return {
        ...state,
        comments,
        loadCommentsStatus: AmityStatus.Succeeded
      }
    }

    case LOAD_COMMENTS_ERROR: {
      return {
        ...state,
        loadCommentsStatus: AmityStatus.Failed
      }
    }

    case ADD_COMMENT_REQUEST: {
      return {
        ...state,
        addCommentStatus: AmityStatus.Loading
      }
    }

    case ADD_COMMENT_SUCCESS: {
      const { comment, parentId } = action.payload
      const comments = state.comments.slice()
      if (action.payload.parentId) {
        const parent = comments.find(comment => {
          return comment.commentId === parentId
        })
        parent.children.unshift(comment)
      } else {
        comments.unshift(comment)
      }
      return {
        ...state,
        comments,
        addCommentStatus: AmityStatus.Succeeded
      }
    }

    case ADD_COMMENT_ERROR: {
      return {
        ...state,
        addCommentStatus: AmityStatus.Failed
      }
    }

    case DELETE_COMMENT_REQUEST: {
      return {
        ...state,
        deleteCommentStatus: AmityStatus.Loading
      }
    }

    case DELETE_COMMENT_SUCCESS: {
      const { commentId, parentId } = action.payload
      const comments = state.comments.slice()
      if (parentId) {
        const parentComment = comments.find(comment => {
          return comment.commentId === parentId
        })
        const childCommentIndex = parentComment.children.findIndex(comment => {
          return comment.commentId === commentId
        })
        parentComment.children.splice(childCommentIndex, 1)
      } else {
        const commentIndex = comments.findIndex(comment => {
          return comment.commentId === commentId
        })
        comments.splice(commentIndex, 1)
      }
      return {
        ...state,
        deleteCommentStatus: AmityStatus.Succeeded,
        comments
      }
    }

    case DELETE_COMMENT_ERROR: {
      return {
        ...state,
        deleteCommentStatus: AmityStatus.Failed
      }
    }

    case FLAG_COMMENT_REQUEST: {
      return {
        ...state,
        flagCommentStatus: AmityStatus.Loading
      }
    }

    case FLAG_COMMENT_SUCCESS: {
      const { commentId, flag } = action.payload
      const comments = state.comments.slice()
      const crawl = comments => {
        comments.forEach(comment => {
          if (comment.commentId === commentId) {
            comment.flaggedByMe = flag
            comment.flagCount += flag ? 1 : -1
          }
          if (comment.children) {
            crawl(comment.children)
          }
        })
      }
      crawl(comments)
      return {
        ...state,
        flagCommentStatus: AmityStatus.Succeeded,
        comments
      }
    }

    case FLAG_COMMENT_ERROR: {
      return {
        ...state,
        flagCommentStatus: AmityStatus.Failed
      }
    }

    case AMITY_CHECK_PERMISSIONS_REQUEST: {
      return {
        ...state,
        checkPermissionsStatus: AmityStatus.Loading
      }
    }

    case AMITY_CHECK_PERMISSIONS_SUCCESS: {
      const { canModerate } = action.payload
      return {
        ...state,
        checkPermissionsStatus: AmityStatus.Succeeded,
        canModerate
      }
    }

    case AMITY_CHECK_PERMISSIONS_ERROR: {
      return {
        ...state,
        checkPermissionsStatus: AmityStatus.Failed
      }
    }

    case CLEAR_COMMENTS: {
      return {
        ...state,
        comments: []
      }
    }

    default: {
      return state
    }
  }
}

export default amity
