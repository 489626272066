import { useState, useEffect } from 'react'

import styles from './AuctionWonOverlay.module.scss'
import classNames from 'classnames'
import storage from '~/services/storage'
import CarouselOverlay from '~/components/Global/AuctionWonOverlay/CarouselOverlay'
import { useRouter } from 'next/router'

const AuctionWonOverlay = ({ salesWon }) => {
  const [isActive, setIsActive] = useState(false)
  const router = useRouter()
  const [salesShowable, setSalesShowable] = useState([])
  const salesShown = JSON.parse(storage.getItem('salesWon') || '[]')
  useEffect(() => {
    const unShownSale = salesWon.find(saleWon => {
      return (
        !salesShown.includes(saleWon.saleClosedEvent.id) &&
        !salesShowable.some(
          saleShowable =>
            saleShowable.saleClosedEvent.id === saleWon.saleClosedEvent.id
        ) &&
        saleWon.product.invoiceUrl &&
        (router.asPath.includes(saleWon.product.slug) ||
          !router.asPath.includes('/products'))
      )
    })
    if (unShownSale) {
      setSalesShowable([...salesShowable, unShownSale])
      if (!isActive) {
        setIsActive(true)
      }
    }
  }, [salesWon])
  return (
    <CarouselOverlay
      name="Checkout"
      overlayClass={
        isActive
          ? classNames(styles.overlay)
          : classNames(styles.overlay, styles.hide)
      }
      salesShown={salesShown}
      salesWon={salesShowable}
      close={true}
      logo={true}
      status={isActive}
      toggle={() => {
        setIsActive(!isActive)
        storage.setItem(
          'salesWon',
          JSON.stringify([
            ...salesShown,
            ...salesShowable.map(saleWon => saleWon.saleClosedEvent.id)
          ])
        )
      }}
    />
  )
}
export default AuctionWonOverlay
