import {
  APPLY_GIFT_CARD_ERROR,
  APPLY_GIFT_CARD_REQUEST,
  APPLY_GIFT_CARD_SUCCESS
} from '~/redux/actions/types'
import { getOrLoadCart } from './get-or-load-cart'

export const applyGiftCardRequest = giftCardCode => {
  return {
    type: APPLY_GIFT_CARD_REQUEST,
    giftCardCode
  }
}

export const applyGiftCardSuccess = cart => {
  return {
    type: APPLY_GIFT_CARD_SUCCESS,
    data: cart
  }
}

export const applyGiftCardError = (error, meta = {}) => {
  return {
    type: APPLY_GIFT_CARD_ERROR,
    error: true,
    meta,
    payload: error
  }
}

export const applyGiftCard =
  giftCardCode =>
  async (dispatch, getState, { api }) => {
    try {
      dispatch(applyGiftCardRequest(giftCardCode))

      const { data: cart } = await getOrLoadCart(dispatch, getState)

      const updatedCart = await api.applyGiftCard(cart.id, giftCardCode)

      dispatch(applyGiftCardSuccess(updatedCart))
    } catch (error) {
      dispatch(applyGiftCardError(error, { giftCardCode }))
      throw error
    }
  }
