module.exports = {
  siteMetadata: {
    title: `JOOPITER`,
    siteUrl: `${process.env.SITEMAP_URL || `https://www.joopiter.com`}`,
    image: `${
      process.env.SITEMAP_URL || 'https://www.joopiter.com'
    }/joopiter-logo.png`,
    description: `Welcome to JOOPITER`,
    social: {
      instagram: 'joopiterofficial',
      tiktok: '@joopiterofficial'
    }
  },
  header: {
    banner: [
      {
        name: 'Pharrell Williams',
        auction: 'Son of a Pharaoh',
        commence: '2022-11-01T16:00:00Z'
      },
      {
        name: 'Pharrell Williams',
        auction: 'Son of a Pharaoh',
        commence: '2022-11-01T16:00:00Z'
      }
    ],
    nav: [{ name: 'Support', slug: '/support' }]
  },
  footer: {
    customer: [
      { name: 'Newsletter Signup', slug: '/newsletter' },
      { name: 'About', slug: '/about' },
      { name: 'Support', slug: '/support' },
      {
        name: 'Instagram',
        slug: 'https://www.instagram.com/joopiterofficial/',
        target: '_blank'
      },
      {
        name: 'TikTok',
        slug: 'https://www.tiktok.com/@joopiterofficial/',
        target: '_blank'
      }
    ],
    legal: [
      {
        name: 'Terms & Conditions',
        slug: '/support',
        query: { slug: 'terms-and-conditions' }
      },
      {
        name: 'Privacy Policy',
        slug: '/support',
        query: { slug: 'privacy-policy' }
      },
      { name: 'Cookies', slug: '#', isCky: true },
      { name: 'Accessibility', slug: '/accessibility' }
    ]
  }
}
