import { useDispatch, useSelector } from 'react-redux'
import { useMemo } from 'react'
import * as actions from '~/redux/actions/amity'
import { bindActionCreators } from 'redux'

export function useAmity() {
  const dispatch = useDispatch()
  const loadCommentsStatus = useSelector(
    state => state.amity.loadCommentsStatus
  )
  const addCommentStatus = useSelector(state => state.amity.addCommentStatus)
  const flagCommentStatus = useSelector(state => state.amity.flagCommentStatus)
  const deleteCommentStatus = useSelector(
    state => state.amity.deleteCommentStatus
  )
  const comments = useSelector(state => state.amity.comments)
  const canModerate = useSelector(state => state.amity.canModerate)

  const loadComments = useMemo(
    () => bindActionCreators(actions.loadComments, dispatch),
    [dispatch]
  )

  const addComment = useMemo(
    () => bindActionCreators(actions.addComment, dispatch),
    [dispatch]
  )

  const deleteComment = useMemo(
    () => bindActionCreators(actions.deleteComment, dispatch),
    [dispatch]
  )

  const flagComment = useMemo(
    () => bindActionCreators(actions.flagComment, dispatch),
    [dispatch]
  )

  const clearComments = useMemo(
    () => bindActionCreators(actions.clearComments, dispatch),
    [dispatch]
  )

  return {
    comments,
    clearComments,
    canModerate,
    loadCommentsStatus,
    addCommentStatus,
    deleteCommentStatus,
    flagCommentStatus,
    loadComments,
    addComment,
    deleteComment,
    flagComment
  }
}
