import { useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import * as actions from '~/redux/actions/user'

export function useUser() {
  const dispatch = useDispatch()
  const status = useSelector(state => state.user.status)
  const userVerificationStatus = useSelector(state => state.user.verified)
  const userIdCheckStatus = useSelector(state => state.user.idCheck)
  const agreedToTermsStatus = useSelector(state => state.user.agreedToTerms)
  const userStripePaymentStatus = useSelector(state => state.user.stripePayment)
  const userStripeAccountStatus = useSelector(state => state.user.stripeAccount)
  const userStripeCusId = useSelector(state => state.user.stripeCusId)
  const hasNewsletterSubscription = useSelector(
    state => state.user.hasNewsletterSubscription
  )

  const user = useSelector(state => state.user.data, shallowEqual)

  const checkUserStripePayment = useMemo(
    () => bindActionCreators(actions.checkUserStripePayment, dispatch),
    [dispatch]
  )
  const checkUserStripeAccount = useMemo(
    () => bindActionCreators(actions.checkUserStripeAccount, dispatch),
    [dispatch]
  )

  const createUserAddress = useMemo(
    () => bindActionCreators(actions.createUserAddress, dispatch),
    [dispatch]
  )

  const loadUser = useMemo(
    () => bindActionCreators(actions.loadUser, dispatch),
    [dispatch]
  )

  const modifyUserAddress = useMemo(
    () => bindActionCreators(actions.modifyUserAddress, dispatch),
    [dispatch]
  )

  const modifyUserPhone = useMemo(
    () => bindActionCreators(actions.modifyUserPhone, dispatch),
    [dispatch]
  )

  const modifyUserName = useMemo(
    () => bindActionCreators(actions.modifyUserName, dispatch),
    [dispatch]
  )

  const modifyUserDefaultAddress = useMemo(
    () => bindActionCreators(actions.modifyUserDefaultAddress, dispatch),
    [dispatch]
  )

  const removeUserAddress = useMemo(
    () => bindActionCreators(actions.removeUserAddress, dispatch),
    [dispatch]
  )

  const userVerified = useMemo(
    () => bindActionCreators(actions.userVerified, dispatch),
    [dispatch]
  )

  const verifyUser = useMemo(
    () => bindActionCreators(actions.verifyUser, dispatch),
    [dispatch]
  )

  const userAgreeToTerms = useMemo(
    () => bindActionCreators(actions.userAgreeToTerms, dispatch),
    [dispatch]
  )

  const userHasNewsletterSubscription = useMemo(
    () => bindActionCreators(actions.userHasNewsletterSubscription, dispatch),
    [dispatch]
  )

  return {
    user,
    userVerificationStatus,
    userIdCheckStatus,
    agreedToTermsStatus,
    status,
    hasNewsletterSubscription,
    userHasNewsletterSubscription,
    createUserAddress,
    loadUser,
    modifyUserAddress,
    modifyUserPhone,
    modifyUserName,
    modifyUserDefaultAddress,
    removeUserAddress,
    userVerified,
    verifyUser,
    userAgreeToTerms,
    userStripePaymentStatus,
    checkUserStripePayment,
    userStripeAccountStatus,
    checkUserStripeAccount,
    userStripeCusId
  }
}
