import {
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_ERROR
} from '~/redux/actions/types'

export const deleteCommentRequest = () => ({
  type: DELETE_COMMENT_REQUEST
})

export const deleteCommentSuccess = payload => ({
  type: DELETE_COMMENT_SUCCESS,
  payload
})

export const deleteCommentError = error => ({
  type: DELETE_COMMENT_ERROR,
  error: true,
  payload: {
    error
  }
})

export const deleteComment =
  ({ commentId, parentId, userId, displayName }) =>
  async dispatch => {
    dispatch(deleteCommentRequest())
    try {
      await fetch('/api/amity/comments', {
        method: 'DELETE',
        body: JSON.stringify({
          commentId,
          userId,
          displayName
        })
      })
      dispatch(deleteCommentSuccess({ commentId, parentId }))
    } catch (error) {
      dispatch(deleteCommentError(error))
      throw error
    }
  }
