import {
  BASTA_CLIENT_BIDS_ERROR,
  BASTA_CLIENT_BIDS_REQUEST,
  BASTA_CLIENT_BIDS_SUCCESS
} from '~/redux/actions/types'

export const clientBidsRequest = ({ saleId, userId }) => {
  return {
    type: BASTA_CLIENT_BIDS_REQUEST,
    saleId: saleId,
    userId: userId
  }
}

export const clientBidsSuccess = bid => {
  return {
    type: BASTA_CLIENT_BIDS_SUCCESS,
    data: bid
  }
}

export const clientBidsError = (error, meta = {}) => {
  return {
    type: BASTA_CLIENT_BIDS_ERROR,
    error: true,
    meta,
    payload: error
  }
}

export const getClientBids = ({ userId }) => {
  const encodedUserId = btoa(userId)
  return async dispatch => {
    try {
      dispatch(clientBidsRequest({ encodedUserId }))
      const bids = await fetch(`/api/basta/clientBids`, {
        method: 'POST',
        body: JSON.stringify({ userId: encodedUserId })
      })
      const bidsResult = await bids.json()
      dispatch(clientBidsSuccess(bidsResult?.clientBids))
    } catch (e) {
      dispatch(clientBidsError(e, { userId }))
      throw e
    }
  }
}
