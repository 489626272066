import {
  ADD_PROMO_CODE_ERROR,
  ADD_PROMO_CODE_REQUEST,
  ADD_PROMO_CODE_SUCCESS,
  ADD_TO_CART_ERROR,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  APPLY_GIFT_CARD_ERROR,
  APPLY_GIFT_CARD_REQUEST,
  APPLY_GIFT_CARD_SUCCESS,
  ASSOCIATE_CART_WITH_USER_ERROR,
  ASSOCIATE_CART_WITH_USER_REQUEST,
  ASSOCIATE_CART_WITH_USER_SUCCESS,
  AUTH_LOGOUT_REQUEST,
  FINALIZE_CHECKOUT_SUCCESS,
  FORGET_CART,
  INITIALIZE_STATE_FROM_STORAGE,
  LOAD_CART_ERROR,
  LOAD_CART_REQUEST,
  LOAD_CART_SUCCESS,
  MODIFY_CART_ERROR,
  MODIFY_CART_QUANTITY_ERROR,
  MODIFY_CART_QUANTITY_REQUEST,
  MODIFY_CART_QUANTITY_SUCCESS,
  MODIFY_CART_REQUEST,
  MODIFY_CART_SUCCESS,
  REMOVE_FROM_CART_ERROR,
  REMOVE_FROM_CART_REQUEST,
  REMOVE_FROM_CART_SUCCESS,
  REMOVE_GIFT_CARD_ERROR,
  REMOVE_GIFT_CARD_REQUEST,
  REMOVE_GIFT_CARD_SUCCESS,
  REMOVE_PROMO_CODE_ERROR,
  REMOVE_PROMO_CODE_REQUEST,
  REMOVE_PROMO_CODE_SUCCESS
} from '~/redux/actions/types'
import initialState from '~/redux/store/initial-state'

const cart = (state = initialState.cart, action) => {
  switch (action.type) {
    case MODIFY_CART_REQUEST:
    case ADD_TO_CART_REQUEST:
    case LOAD_CART_REQUEST:
    case ASSOCIATE_CART_WITH_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      }

    case REMOVE_FROM_CART_REQUEST:
    case MODIFY_CART_QUANTITY_REQUEST:
      return {
        ...state,
        isFetching: false,
        error: null
      }

    case LOAD_CART_SUCCESS:
    case MODIFY_CART_SUCCESS:
    case ADD_TO_CART_SUCCESS:
    case MODIFY_CART_QUANTITY_SUCCESS:
    case REMOVE_FROM_CART_SUCCESS:
    case ASSOCIATE_CART_WITH_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        data: action.data
      }

    case FINALIZE_CHECKOUT_SUCCESS:
    case FORGET_CART:
      return {
        ...state,
        isFetching: false,
        error: null,
        data: {}
      }

    case LOAD_CART_ERROR:
    case ADD_TO_CART_ERROR:
    case MODIFY_CART_ERROR:
    case ASSOCIATE_CART_WITH_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false
      }

    case REMOVE_FROM_CART_ERROR:
    case MODIFY_CART_QUANTITY_ERROR:
      return {
        ...state,
        error: action.payload
      }

    case ADD_PROMO_CODE_REQUEST:
      return {
        ...state,
        promoCode: {
          isFetching: true
        }
      }

    case ADD_PROMO_CODE_SUCCESS:
      return {
        ...state,
        data: action.data,
        promoCode: {
          isFetching: false
        }
      }

    case ADD_PROMO_CODE_ERROR:
      return {
        ...state,
        promoCode: {
          isFetching: false
        }
      }

    case REMOVE_PROMO_CODE_REQUEST:
      return {
        ...state,
        promoCode: {
          isFetching: true
        }
      }

    case REMOVE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        data: action.data,
        promoCode: {
          isFetching: false
        }
      }

    case REMOVE_PROMO_CODE_ERROR:
      return {
        ...state,
        promoCode: {
          isFetching: false
        }
      }

    case APPLY_GIFT_CARD_REQUEST:
      return {
        ...state,
        giftCard: {
          isFetching: true
        }
      }

    case APPLY_GIFT_CARD_SUCCESS:
      return {
        ...state,
        data: action.data,
        giftCard: {
          isFetching: false
        }
      }

    case APPLY_GIFT_CARD_ERROR:
      return {
        ...state,
        giftCard: {
          isFetching: false
        }
      }

    case REMOVE_GIFT_CARD_REQUEST:
      return {
        ...state,
        giftCard: {
          isFetching: true
        }
      }

    case REMOVE_GIFT_CARD_SUCCESS:
      return {
        ...state,
        data: action.data,
        giftCard: {
          isFetching: false
        }
      }

    case REMOVE_GIFT_CARD_ERROR:
      return {
        ...state,
        giftCard: {
          isFetching: false
        }
      }

    case INITIALIZE_STATE_FROM_STORAGE: {
      const { id } = action.payload.cart.data
      const lineItems = []

      return {
        ...state,
        data: {
          ...state.data,
          lineItems,
          id
        }
      }
    }

    case AUTH_LOGOUT_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          number: null,
          token: null
        }
      }

    default:
      return state
  }
}

export default cart
