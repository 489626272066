import {
  CHECK_USER_STRIPE_PAYMENT,
  CHECK_USER_STRIPE_ACCOUNT,
  CHECK_USER_STRIPE_CUSID
} from '../types'

export const checkUserStripePayment = payload => ({
  type: CHECK_USER_STRIPE_PAYMENT,
  payload
})

export const checkUserStripeAccount = payload => ({
  type: CHECK_USER_STRIPE_ACCOUNT,
  payload
})

export const checkUserStripeCusId = payload => ({
  type: CHECK_USER_STRIPE_CUSID,
  payload
})

/**
 * Creates user account on Stripe
 * @param {Object} data - {email: ...}
 * @returns {Promise}
 */
export const createUserStripeAccount =
  () =>
  async (dispatch, getState, { api }) => {
    const { auth } = getState()
    const response = await api.getUser(auth.token)

    fetch('/api/stripe/customers', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        shopifyCustomerId: response.id,
        stripeCustomerData: {
          email: response.email
        }
      })
    })
      .then(response => response.json())
      .then(result => {
        dispatch(checkUserStripeAccount(result.status ? result.status : false))
      })
      .catch(err => {
        throw new Error(err)
      })
  }
