import {
  FLAG_COMMENT_REQUEST,
  FLAG_COMMENT_SUCCESS,
  FLAG_COMMENT_ERROR
} from '~/redux/actions/types'

export const flagCommentRequest = () => ({
  type: FLAG_COMMENT_REQUEST
})

export const flagCommentSuccess = payload => ({
  type: FLAG_COMMENT_SUCCESS,
  payload
})

export const flagCommentError = error => ({
  type: FLAG_COMMENT_ERROR,
  error: true,
  payload: {
    error
  }
})

export const flagComment =
  ({ commentId, userId, displayName, flag }) =>
  async dispatch => {
    dispatch(flagCommentRequest())
    try {
      await fetch('/api/amity/comments', {
        method: 'PUT',
        body: JSON.stringify({
          commentId,
          userId,
          displayName,
          flag
        })
      })
      dispatch(flagCommentSuccess({ commentId, flag }))
    } catch (error) {
      dispatch(flagCommentError(error))
      throw error
    }
  }
