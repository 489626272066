import {
  TOGGLE_VERIFICATION_OVERLAY,
  TOGGLE_ALERT_OVERLAY,
  TOGGLE_CONTENT_OVERLAY
} from '~/redux/actions/types'
import initialState from '~/redux/store/initial-state'

const overlay = (state = initialState.overlay, action) => {
  switch (action.type) {
    case TOGGLE_CONTENT_OVERLAY:
      return {
        ...state,
        content: state.content ? false : true
      }

    case TOGGLE_VERIFICATION_OVERLAY:
      return {
        ...state,
        verification: state.verification ? false : true,
        verificationStep: action.payload
      }

    case TOGGLE_ALERT_OVERLAY:
      return {
        ...state,
        alert: state.alert ? false : true
      }

    default:
      return state
  }
}

export default overlay
