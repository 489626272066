import {
  ADD_TO_CART_ERROR,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS
} from '~/redux/actions/types'
import { getOrLoadCart } from './get-or-load-cart'

export const addToCartRequest = sku => {
  return {
    type: ADD_TO_CART_REQUEST,
    sku: sku
  }
}

export const addToCartSuccess = (cart, variant, quantity) => {
  return {
    type: ADD_TO_CART_SUCCESS,
    data: cart,
    variant,
    quantity
  }
}

export const addToCartError = (error, meta = {}) => {
  return {
    type: ADD_TO_CART_ERROR,
    error: true,
    meta,
    payload: error
  }
}

export const addToCart = (
  variantId,
  quantity,
  placement,
  position,
  customAttributes
) => {
  return async (dispatch, getState, { api }) => {
    try {
      dispatch(addToCartRequest(variantId))

      const { data: cart } = await getOrLoadCart(dispatch, getState)
      const { user } = getState()

      const updatedCart = await api.addOrderItem(cart.id, {
        variantId,
        quantity,
        user: user.data,
        placement,
        position,
        customAttributes
      })

      const globalVariantId = `gid://shopify/ProductVariant/${variantId}`

      const addLineItem = updatedCart.lineItems.find(
        li => li.variant.id === globalVariantId
      )
      const variant = { ...addLineItem.variant }

      dispatch(addToCartSuccess(updatedCart, variant, quantity))
    } catch (error) {
      dispatch(addToCartError(error, { variantId, quantity }))
      throw error
    }
  }
}
