import {
  ITEM_UPDATE_ERROR,
  ITEM_UPDATE_REQUEST,
  ITEM_UPDATE_SUCCESS
} from '~/redux/actions/types'

export const updateItemRequest = ({ saleId, userId }) => {
  return {
    type: ITEM_UPDATE_REQUEST,
    saleId: saleId,
    userId: userId
  }
}

export const updateItemSuccess = item => {
  return {
    type: ITEM_UPDATE_SUCCESS,
    data: item
  }
}

export const updateItemError = (error, meta = {}) => {
  return {
    type: ITEM_UPDATE_ERROR,
    error: true,
    meta,
    payload: error
  }
}
