import {
  AMITY_CHECK_PERMISSIONS_REQUEST,
  AMITY_CHECK_PERMISSIONS_SUCCESS,
  AMITY_CHECK_PERMISSIONS_ERROR
} from '~/redux/actions/types'

export const checkPermissionsRequest = () => ({
  type: AMITY_CHECK_PERMISSIONS_REQUEST
})

export const checkPermissionsSuccess = payload => ({
  type: AMITY_CHECK_PERMISSIONS_SUCCESS,
  payload
})

export const checkPermissionsError = error => ({
  type: AMITY_CHECK_PERMISSIONS_ERROR,
  error: true,
  payload: {
    error
  }
})

export const checkPermissions = () => async (dispatch, getState) => {
  dispatch(checkPermissionsRequest())
  const { user } = getState()
  try {
    const params = new URLSearchParams({
      userId: user.data.id
    })
    const response = await fetch('/api/amity/permissions?' + params)
    const { canModerate } = await response.json()
    dispatch(checkPermissionsSuccess({ canModerate }))
  } catch (error) {
    dispatch(checkPermissionsError(error))
    throw error
  }
}
