import { IDCHECK_USER, IDCHECK_USER_SUCCESS } from '../types'

export const idChecked = () => ({
  type: IDCHECK_USER
})

export const idCheckUserSuccess = payload => ({
  type: IDCHECK_USER_SUCCESS,
  payload
})
